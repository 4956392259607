export const isProd = process.env.NODE_ENV === 'production';

export const CONTACT_TYPE_PHONE=1;
export const CONTACT_TYPE_EMAIL=2;
export const CONTACT_TYPE_OTHER=7;
export const CONTACT_CATEGORY_HOME=4;
export const CONTACT_CATEGORY_BUSINESS=1;

export const ROLE_AGENT=5;
export const ROLE_AGENT_IN_TRAINING=21;
export const ROLE_REGIONAL_PRINCIPAL=6;
export const ROLE_REGIONAL_OFFICERS=7;
export const ROLE_REGIONAL_STAFF=8;
export const ROLE_OFFICE_STAFF=9;
export const ROLE_BROKER_OWNER=4;
export const ROLE_OFFICE_PRINCIPAL=20;

export const ROLES_MAP = {
	[ROLE_AGENT]: 'Agent',
	[ROLE_AGENT_IN_TRAINING]: 'Agent in Training',
	[ROLE_REGIONAL_PRINCIPAL]: 'Regional Principal',
	[ROLE_REGIONAL_OFFICERS]: 'Regional Officer',
	[ROLE_REGIONAL_STAFF]: 'Regional Staff',
	[ROLE_OFFICE_STAFF]: 'Office Staff',
	[ROLE_BROKER_OWNER]: 'Broker/Owner',
	[ROLE_OFFICE_PRINCIPAL]: 'Office Principal',
};


export const REMAX_ENTITY_CATEGORY_ID_REGION=2;
export const REMAX_ENTITY_CATEGORY_ID_OFFICE=3;
export const REMAX_ENTITY_CATEGORY_ID_COMPANY=4;

export const SYSTEM_ACCESS_TIER_GLOBAL=1;
export const SYSTEM_ACCESS_TIER_REGION=2;

export const AWARD_TYPE_CLUB=1;
export const AWARD_TYPE_CAREER=2;
export const AWARD_TYPE_TENURE=3;

export const FRANCHISE_AGREEMENT_SEGMENT_TYPE_NORMAL=1;
export const FRANCHISE_AGREEMENT_SEGMENT_TYPE_SATELLITE=2;
export const FRANCHISE_AGREEMENT_SEGMENT_TYPE_VIRTUAL=3;

export const NOTIFICATION_TYPES = {
    OFFICE_FRANCHISES: 1,
    ANNUAL_DUES: 2,
    EXPIRING_CONTRACTS: 4,
    ANNUAL_DUES_CLOSED_BY_REGION: 5,
    ANNUAL_DUES_OVERDUE: 6,
    ANNUAL_DUES_REPORT_AVAILABLE: 7,
    ANNUAL_DUES_REPORT_APPROVED_OR_REJECTED: 8,
};

export const GENDER_MAP = {
	0: 'male',
	1: 'female',
	2: 'diverse',
};

export const OFFICE_STATUS = {
    1: 'active',
    2: 'on hold',
    3: 'in process',
    4: 'renewal pending',
    6: 'in renewal',
    0: 'inactive',
};

export const OFFICE_STATUS_COLORS = {
    1: '#009500',
    2: '#b2892b',
    3: 'orangered',
    4: '#07b9eb',
    6: '#8ea98e',
    0: '#b22b2e',
};

export const ANNUAL_DUES_STATUS = {
	0: 'open',
    1: 'submitted by Region',
    2: 'closed',
    default: 'Report not available yet',
};

export const ANNUAL_DUES_STATUS_COLORS = {
    0: '#009500',
    1: '#b2892b',
    2: 'orangered',
    default: '#777',
};

export const PERSON_TITLE_TYPE = {
    1: 'Active',
    2: 'Principal of Franchisee',
    3: 'Silent',
    default: 'Silent',
};

export const feeReliefTypes = [
	{ label: 'Fee Reduction', value: '0', },
	{ label: 'Deferment', value: '1', },
];

export const applyTypes = [
	{ label: 'New members only', value: '0', },
	{ label: 'All members', value: '1', },
];

// Annual Dues Action Types
export const ANNUAL_DUES_TYPE_DELAY= 0;
export const ANNUAL_DUES_TYPE_WRITE_OFF= 1;
export const ANNUAL_DUES_TYPE_AGENT_IN_TRAINING= 2;
export const ANNUAL_DUES_TYPE_ADD_PERSON_IN_ADVANCE= 3;
export const ANNUAL_DUES_TYPE_UNDO= 4;
export const ANNUAL_DUES_TYPE_APPROVE= 5;
export const ANNUAL_DUES_TYPE_REJECT= 6;
export const ANNUAL_DUES_TYPE_ADDED_BY_SYSTEM_ROBOT= 7;
export const ANNUAL_DUES_TYPE_DELAYED_BY_SYSTEM_ROBOT= 8;

export const ANNUAL_DUES_TYPE_MAP = {
	[ANNUAL_DUES_TYPE_DELAY]: 'Delay',
	[ANNUAL_DUES_TYPE_WRITE_OFF]: 'Write-off',
	[ANNUAL_DUES_TYPE_AGENT_IN_TRAINING]: 'Agent in Training',
	[ANNUAL_DUES_TYPE_ADD_PERSON_IN_ADVANCE]: 'Add Person in Advance',
	[ANNUAL_DUES_TYPE_UNDO]: 'Undo',
	[ANNUAL_DUES_TYPE_APPROVE]: 'Approve',
	[ANNUAL_DUES_TYPE_REJECT]: 'Reject',
	[ANNUAL_DUES_TYPE_ADDED_BY_SYSTEM_ROBOT]: 'Added by System Robot',
	[ANNUAL_DUES_TYPE_DELAYED_BY_SYSTEM_ROBOT]: 'Delayed by System Robot',
};

export const ANNUAL_DUES_TYPE_COLOR_MAP = {
	[ANNUAL_DUES_TYPE_DELAY]: '#fdf3e4',
	[ANNUAL_DUES_TYPE_WRITE_OFF]: '#f6cab7',
	[ANNUAL_DUES_TYPE_AGENT_IN_TRAINING]: '#bbebf9',
	[ANNUAL_DUES_TYPE_ADD_PERSON_IN_ADVANCE]: '#c5f5e6',
	[ANNUAL_DUES_TYPE_ADDED_BY_SYSTEM_ROBOT]: '#c5f5e6',
	[ANNUAL_DUES_TYPE_DELAYED_BY_SYSTEM_ROBOT]: '#fdf3e4',
};

// Annual Dues Payment Types
export const ANNUAL_DUES_FINANCE_TYPE_PAYMENT= 0;
export const ANNUAL_DUES_FINANCE_TYPE_CORRECTION= 1;
export const ANNUAL_DUES_FINANCE_TYPE_AD_REPORT= 2;
export const ANNUAL_DUES_FINANCE_TYPE_WAIVER= 3;

// Annual Dues Fee Relief Types
export const ANNUAL_DUES_FEE_RELIEF_TYPE_FEE_REDUCTION= 0;
export const ANNUAL_DUES_FEE_RELIEF_TYPE_DEFERMENT= 1;

export const ANNUAL_DUES_FEE_RELIEF_TYPES_ARRAY = {
	[ANNUAL_DUES_FEE_RELIEF_TYPE_FEE_REDUCTION]: "Fee Reduction",
	[ANNUAL_DUES_FEE_RELIEF_TYPE_DEFERMENT]: "Deferment"
};

// Annual Dues Apply Types
export const ANNUAL_DUES_FEE_RELIEF_APPLY_TYPE_NEW_MEMBERS= 0;
export const ANNUAL_DUES_FEE_RELIEF_APPLY_TYPE_ALL_MEMBERS= 1;

export const ANNUAL_DUES_FEE_RELIEF_APPLY_TYPE_ARRAY = {
	[ANNUAL_DUES_FEE_RELIEF_APPLY_TYPE_NEW_MEMBERS]: "New members only",
	[ANNUAL_DUES_FEE_RELIEF_APPLY_TYPE_ALL_MEMBERS]: "All members"
};

// Region Annual Dues Status
export const REGION_ANNUAL_DUES_STATUS_OPEN= 0;
export const REGION_ANNUAL_DUES_STATUS_REGION_CLOSED= 1;
export const REGION_ANNUAL_DUES_STATUS_RXE_CLOSED= 2;

//Award Action
export const AWARD_UNDO= 0;
export const AWARD_APPROVE= 1;
export const AWARD_REJECT= 2;

//Office Status
export const OFFICE_INACTIVE= 0;
export const OFFICE_ACTIVE= 1;
export const OFFICE_HOLD= 2;
export const OFFICE_IN_PROCESS= 3;
export const OFFICE_RENEWAL_PENDING= 4;

//Report
export const REPORT_TEMPLATE_SECTION_PERFORMANCE= 1;
export const REPORT_TEMPLATE_SECTION_TOPLIST= 2;
export const REPORT_TEMPLATE_SECTION_RECURRING= 3;

export const REPORT_TIMESPAN_LIFETIME= 1;
export const REPORT_TIMESPAN_YTD= 2;

// Termination reasons
export const TERMINATION_REASON_DUPLICATE_PROFILE = 17;

export const PERSON_GENDER = {
	0: 'male',
	1: 'female',
	2: 'diverse'
};

export const CURL_MAX_RETRIES= 3;
export const CURL_RETRY_AFTER_MS = 500;


export const CONTACT_MANAGEMENT_CONTACT_TYPE_INTERNAL= 0;
export const CONTACT_MANAGEMENT_CONTACT_TYPE_EXTERNAL= 1;
export const CONTACT_MANAGEMENT_CONTACT_TYPE_COMPANY= 2;
export const CONTACT_MANAGEMENT_CONTACT_TYPE_REGION= 3;

export const ANNUAL_DUES_FINANCE_TYPE_MAP = {
	[ANNUAL_DUES_FINANCE_TYPE_PAYMENT]: 'Payment',
	[ANNUAL_DUES_FINANCE_TYPE_CORRECTION]: 'Correction',
	[ANNUAL_DUES_FINANCE_TYPE_AD_REPORT]: 'Annual Dues Report',
	[ANNUAL_DUES_FINANCE_TYPE_WAIVER]: 'Waiver',
};

export const CONTACT_TYPE_MAP = {
	person: CONTACT_MANAGEMENT_CONTACT_TYPE_EXTERNAL,
	company: CONTACT_MANAGEMENT_CONTACT_TYPE_COMPANY,
};
