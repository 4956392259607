import { BaseService } from '../base.service';

export class AffiliateDashboardService extends BaseService {
  static get entity() {
    return 'affiliateDashboard';
  }

  static async getAgentCountData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentCount?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentProductivityData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentProductivity?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAverageTransactionsPerYearData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentAverageTransactionsPerYear?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentAverageTransactionsPerTenureGroup(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentAverageTransactionsPerTenureGroup?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
