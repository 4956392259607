import { BaseService } from '@/services/base.service';
import { CubeService } from '@/services/modules/cube.service';
import { promiseAll } from "@/utils/promiseAll";

export const getDefaultState = () => ({
	unEditableTemplatesIDs: [25, 35, 45, 48, 49],
	report: null,
	reportData: [],
	showReportData: false,
	loadingReportData: false,
	reportTemplateSection: null,
	reportTemplate: null,
	regions: [],
	currencies: [
		{ value: 'local', label: 'Local Currency', },
		{ value: 'rxd', label: 'Remax Dollar', },
		{ value: 'eur', label: 'Euro', },
	],
});

export async function reportData({ commit }, query: any) {
	try {
		commit('setState', { loadingReportData: true, });
		const reportData = await CubeService.getReportData(query);
		commit('setState', { reportData: reportData || [], showReportData: true, });
	} catch(e) {
		throw e;
	} finally {
		commit('setState', { loadingReportData: false, });
	}
}

export async function init({ commit }, opts: any = {}) {
	const [
		reportRes,
		reportTemplateSectionRes,
		regions,
	] = await promiseAll([
		opts.reportID ? BaseService.get(`report/${opts.reportID}`) : null,
		BaseService.get(`reportTemplateSection/${opts.reportTemplateSectionID}`),
		BaseService.regionsDropdown(),
	]);

	const reportTemplateSection = reportTemplateSectionRes?.data?.result || null;
	const reportTemplate = (reportTemplateSection?.reportTemplates || []).filter(t => `${t.ID}` === `${opts.reportTemplateID}`)[0] || null;

	const report = reportRes?.data?.result || null;
	if (report) report.reportFilter = JSON.parse(report.reportFilter || '{}');

	commit('setState', {
		report,
		reportTemplateSection,
		reportTemplate,
		regions: (regions?.data?.result || []).map(r => ({
			value: r.entryKey,
			label: r.entryValue,
		})),
	});
}
