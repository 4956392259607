import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, regionID) {
	try {
		await promiseAll([
			region(store, {}, regionID),			
		]);
	} catch(e) { throw e; }
}

export async function region(store, params = {}, regionID?: any) {
	try {
		regionID = regionID || store.state.region?.ID;
		if (regionID) {
			const [region, currencyPayment, annualDuesReportGetOpenInfo] = await promiseAll([
				BaseService.get(`region/${regionID}`),
				BaseService.get(`region/${regionID}/currencyPayment`),
				BaseService.get(`region/${regionID}/annualDuesReportGetOpenInfo`),
				monthlyAnnualDuesOverview(store, {}, regionID),
			]);
	
			store.commit('setState', {
				region: region?.data?.result,
				currencyPayment: currencyPayment?.data,
				annualDuesReportGetOpenInfo: annualDuesReportGetOpenInfo?.data?.result,
			});
		}
	} catch(e) { console.log(e); /**/ }
}

export async function monthlyAnnualDuesOverview({ commit, state }, params = {}, regionID?: any) {
	try {
		regionID = regionID || state.region?.ID;
		const { queryParams, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || 12;
		const qParams = { ...state.monthlyAnnualDuesOverview.queryParams, ...queryParams, };

		commit('setState', { monthlyAnnualDuesOverview: { ...state.monthlyAnnualDuesOverview, loading: true, queryParams: qParams, }, });

		const res = await BaseService.get(`region/${regionID}/monthlyAnnualDuesOverview`, {
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		});
		const totalRows = res?.data?.info?.numRows || 0;
		commit('setState', {
			selected: [],
			monthlyAnnualDuesOverview: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list: res?.data?.result || [],
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { monthlyAnnualDuesOverview: { ...state.monthlyAnnualDuesOverview, loading: false, }, });
		throw e; 
	}
}
