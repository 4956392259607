import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class PersonEditorService extends BaseService {
  static get entity() {
    return 'person';
  }

  static insertPerson(payload) {
    return this.post(`persons`, payload);
  }

  static updatePerson(personID, payload) {
    return this.put(`person/${personID}`, payload);
  }

  static insertNote(personID, entity) {
    return this.post(`person/${personID}/note`, entity);
  }

  static updateNote(note_id, entity) {
    return this.put(`personNote/${note_id}`, entity);
  }

  static deleteNote(note_id) {
    return this._delete(`personNote/${note_id}`);
  }

  static person(personID, params) {
    return this.get(`person/${personID || ''}`, params);
  }

  static count(params) {
    return this.get(`persons/count`, params);
  }

  static designation(personID, params) {
    return this.get(`person/${personID || ''}/designation`, params);
  }

  static language(personID, params) {
    return this.get(`person/${personID || ''}/language`, params);
  }

  static emailCommunicationTag(personID, params) {
    return this.get(`person/${personID || ''}/emailCommunicationTag`, params);
  }

  static primaryTitleHistories(personID, params) {
    return this.get(`person/${personID || ''}/primaryTitleHistories`, { sort: 'validFrom', ...params });
  }

  static contactInformationEntry(personID, params) {
    return this.get(`person/${personID || ''}/contactInformationEntry`, params);
  }

  static licenseNumbers(personID, params) {
    return this.get(`person/${personID || ''}/licenseNumbers`, { sort: 'createdAt', ...params });
  }

  static regionCurrencyReporting(regionID, params) {
    return this.get(`region/${regionID || ''}/currencyReporting`, params);
  }

  static ranks(personID, params) {
    return this.get(`person/${personID || ''}/ranks`, params);
  }

  static statistics(personID, params) {
    return this.get(`person/${personID || ''}/statistics`, params);
  }

  static offices(personID, params) {
    return this.get(`person/${personID || ''}/offices`, params);
  }

  static personSuffix(params) {
    return this.get(`personSuffix`, { sort: '-Array', ...params });
  }

  static region(regionID, params) {
    return this.get(`region/${regionID || ''}`, params);
  }

  static canEditPersonTerminationReason(id, params) {
    return this.get(`personHasTermination/${id || ''}/canEditPersonTerminationReason`, params);
  }

  static awards(personID, params) {
    return this.get(`person/${personID || ''}/awards`, params);
  }

  static amountOfYearsGettingClubAwards(personID, params) {
    return this.get(`person/${personID || ''}/amountOfYearsGettingClubAwards`, params);
  }

  static active(personID, params) {
    return this.get(`person/${personID || ''}/active`, params);
  }

  static teamLeader(id, params) {
    return this.get(`team/${id || ''}/leader`, params);
  }

  static relationWithInactive(personID, params) {
    return this.get(`person/${personID || ''}/relationWithInactive`, params);
  }

  static remaxTitles(personID, params) {
    return this.get(`person/${personID || ''}/history`, { sort: '-valid_from', only_active: '1', ...params });
  }

  static isInActiveOfficeShare(personID, params) {
    return this.post(`person/${personID || ''}/isInActiveOfficeShare`, params);
  }

  static teamHistories(personID, params) {
    return this.get(`person/${personID || ''}/teamHistories`, {
      ...params,
    });
  }

  static systemAccesses(personID, params) {
    return this.get(`systemAccesses`, {
      limit: '0,35',
      'filter[personID][eq]': `${personID || ''}`,
      sort: 'personName,remaxTitle',
      ...params,
    });
  }

  static note(personID, params) {
    return this.get(`person/${personID || ''}/note`, { sort: '-noteDate', ...params });
  }

  static personalNotesCategories(params) {
    return this.get(`personNotes/categories`, { sort: 'category', ...params });
  }

  static editHistory(personID, params) {
    return this.get(`person/${personID}/editHistory`, {
      limit: '0,35',
      sort: '-changeDate',
      ...params,
    });
  }

  static team(personID, params) {
    return this.get(`person/${personID}/team`, { sort: 'name', ...params });
  }

  static async getTerminationReasons(params) {
    return this.get(`agentTerminationReason`, params);
  }

  static async insertProfilePicture(params) {
    return this.post(`person/${params.id}/picture`, params.image);
  }
  static async getProfilePicture(hash) {
    return this.get(`storage/${hash}`);
  }
  
  static async deleteProfilePicture(params) {
    return this.delete(`person/${params.id}/picture`);
  }

  static async getSinglePerson(id) {
    console.log(id);
    return this.get(`person/${id}`);
  }
}
