import { BaseService } from '../base.service';

export class FranchiseSalesDashboardService extends BaseService {

    static async getSalesDataForContractType(regionNames, contractType, currentYear=false) {
        const url = `/v1/franchiseSalesDashboard/getSalesDataForContractType/?regionNames=${(regionNames && regionNames.length > 0)? regionNames.join(',') : ''},contractType=${contractType}, currentYear=${currentYear}`
        console.log(`getSalesDataForContractType() -> (${url})`);
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getActiveFSConsultants(currentYear=false) {
        const url = `/v1/franchiseSalesDashboard/getActiveFSConsultants?currentYear=${currentYear}`
        console.log(`getSalesDataForContractType() -> (${url})`);
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getFSConsultantsDropdown() {
        const url = `/v1/franchiseSalesDashboard/getFSConsultantsDropdown`
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getFSConsultantsPerformanceOverview() {
        const url = `/v1/franchiseSalesDashboard/getFSConsultantsPerformanceOverview`
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getRegionsOverview() {
        const url = `/v1/franchiseSalesDashboard/getRegionsOverview`
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getTotalOfficesCurrentYear() {
        const url = `/v1/franchiseSalesDashboard/getTotalOfficesCurrentYear`
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

    static async getTotalOfficesPreviousYear() {
        const url = `/v1/franchiseSalesDashboard/getTotalOfficesPreviousYear`
        const res = await this.request().get(url)
        return { result: res.data, message: res.data.message, headers: res.headers }
    }

}