import { OfficeService } from '@/services/modules/office.service';
import { OfficeEditorService } from '@/services/modules/officeEditor.service';
import * as officeData from '../mock-data/office';
import {OfficeGroupEditorService} from '@/services/modules/officeGroupsEditor.service';
import { BaseService } from '../../services/base.service';
import store from '@/store';
import { promiseAll } from '@/utils/promiseAll';

export default {
  namespaced: true,
  state: {
    offices: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    currentOffice: {},
	officeInfo: null,
	officeInfoLoaded: false,
	regionInfo: null,
	contactInformationEntry: [],
    currentOfficeExtendedData : {},
    currentOfficeCurrencyData : {},
    loadingCurrentOffice: false,
    officeList: [],
    errorList: [],
    officeGroups: [],
    loadingOfficeGroups: false,
    loadingData: false,
    personsList: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    officeTeamsList: [],
    loadingPersonsList: false,
    loadingOfficeTeamsList: false,
    activePersonsListOnly: true,
    activeOfficeTeamsListOnly: true,
    activeOfficeGroupListOnly: true,
    savingOffice: false,
    currentSnapshot: null,
    snapshotDropdown: [],
    officeShareSnapshotData: {
      ID: null,
      modifiedAt: null,
      officeID: null,
      officeShares: [],
      validFrom: null,
    },
    office: [],
    officeRegionsDropdown: [],
    regionsDropdown: [],
    officeStatistics: [],
    loadingOfficeShareSnapshotData: false,
    owners: [],
    ownerTypes: [],
    ownershipTypes: [],
    loadingOwners: false,
    groupsInRegion: [],
    contactInformationList: [],
    franchiseSalesReps: [],
    franchiseSizeSelect: [],
    developmentSchedules: [],
    developmentSchedules2: [],
    noteList: [],
    history: [],
    specialisationsSelect: [],
    officeLevelsSelect: [],
    officeRankingsSelect: [],
    regionsSelect: [],
    businessDevelopersSelect: [],
    developmentSchedulesSnapShot: [],
    franchise: {},
    OfficesDropdown: [],
    roles: [],
    franchiseHistorySnapShots: [],
    franchiseHistorySegment: [],
    initialSegmentHistory: [],
    initialHistory: [],
    mainOfficeName: [],
    officeSize: [],
    ungrouped: {
      list: [],
      loading: false,
    },
	phoneCodes: [],
	phonecodesWithoutISO: [],
	countries: [],
	isFranchiseAgreementCreate: false,
  },
  getters: {
    getOfficeList: (state) => state.officeList,
    getOfficesList: (state) => state.offices.list,
    getCurrentOffice: (state) => state.currentOffice,
    getCurrentOfficeExtendedData: (state) => state.currentOfficeExtendedData,
    getCurrentOfficeRegionCurrencyData: (state) => state.currentOfficeRegionCurrency,
    getErrorList: (state) => state.errorList,
    getNoteList: (state) => state.noteList,
    getHistory: (state) => state.history,
    getUngrouped: (state) => state.ungrouped,
  },
  mutations: {
    setRegionsDropdown(state, data) {
      state.regionsDropdown = data;
    },
    setOffices(state, data) {
      state.offices = { ...state.offices, ...data, };
    },
    setOfficeRegionsDropdown(state, data) {
      state.officeRegionsDropdown = data;
    },
    SET_CURRENT_OFFICE(state, office) {
      state.currentOffice = office;
    },
    setLoadingCurrentOffice(state, loading) {
      state.loadingCurrentOffice = loading;
    },
    SET_OFFICE_LIST(state, officeList) {
      state.officeList = officeList;
    },
    SET_ERRORS(state, errorList) {
      state.errorList = errorList;
    },
    setLoadingOfficeGroups(state, loading) {
      state.loadingOfficeGroups = loading;
    },
    setOfficeGroups(state, data) {
      state.officeGroups = data;
    },
    setPersonsList(state, data = []) {
      state.personsList = data;
    },
    setOfficeTeamsList(state, data = []) {
      state.officeTeamsList = data;
    },
    setLoadingPersonsList(state, loading) {
      state.loadingPersonsList = loading;
    },
    setLoadingOfficeTeamsTable(state, loading) {
      state.loadingOfficeTeamsList = loading;
    },
    setSavingOffice(state, saving) {
      state.savingOffice = saving;
    },
    setActivePersonsListOnly(state, activeOnly = true) {
      state.activePersonsListOnly = activeOnly;
    },
    setActiveOfficeTeams(state, activeOnly = true) {
      state.activeOfficeTeamsListOnly = activeOnly;
    },
    setActiveOfficeGroupListOnly(state, activeOnly = true) {
      state.activeOfficeGroupListOnly = activeOnly;
    },
    setCurrentSnapshot(state, data) {
      state.currentSnapshot = data;
    },
    setSnapshotDropdown(state, data) {
      state.snapshotDropdown = data;
      state.currentSnapshot = data && data.length ? data[0].ID : null;
    },
    setOfficeShareSnapshotData(state, data) {
      state.officeShareSnapshotData = { ...state.officeShareSnapshotData, ...data };
    },
    setLoadingOfficeShareSnapshotData(state, loading) {
      state.loadingOfficeShareSnapshotData = loading;
    },
    setOwners(state, data) {
      state.owners = data;
    },
    setOwnerTypes(state, data) {
      state.ownerTypes = data;
    },
    setOwnershipTypes(state, data) {
      state.ownershipTypes = data;
    },
    setLoadingOwners(state, loading = true) {
      state.loadingOwners = loading;
    },
    setGroupsInRegion(state, data) {
      state.groupsInRegion = data;
    },
    setContactInformationList(state, data) {
      state.contactInformationList = data;
    },
    setFranchiseSalesReps(state, data) {
      state.franchiseSalesReps = data;
    },
    setFranchiseSizeSelect(state, data) {
      state.franchiseSizeSelect = data;
    },
    setDevelopmentSchedules(state, data) {
      state.developmentSchedules = data;
    },
    setDevelopmentSchedulesSnapShot(state, data) {
      state.developmentSchedulesSnapShot = data;
    },
    setDevelopmentSchedules2(state, data) {
      state.developmentSchedules2 = data;
    },
    setFranchiseHistorySnapShots(state, data) {
      state.franchiseHistorySnapShots = data;
    },
    setFranchiseHistoryBySnapShot(state, data) {
      state.franchiseHistory = data;
    },
    setFranchiseSegmentData(state, data) {
      state.franchiseHistorySegment = data;
    },
    setInitialSegmentHistory(state, data) {
      state.initialSegmentHistory = data;
    },
    setInitialHistory(state, data) {
      state.initialHistory = data;
    },
    //Notes
    SET_NOTE_LIST(state, data) {
      state.noteList = data;
    },
    insertNote(state, data) {
      if (data && data.ID) state.noteList = [...state.noteList, data];
    },
    updateNote(state, note) {
      state.noteList = state.noteList.map((n) => (`${n.ID}` !== `${note.ID}` ? n : { ...n, ...note }));
    },
    removeNote(state, noteID) {
      state.noteList = state.noteList.filter((n) => n.ID !== noteID);
    },
    appendDevelopmentSchedule(state, newRow) {
      state.developmentSchedules = [...state.developmentSchedules, newRow];
    },
    removeDevelopmentSchedule(state, index) {
      state.developmentSchedules = state.developmentSchedules.filter((_, i) => i !== Number(index));
    },
    //Change History
    SET_HISTORY(state, data) {
      state.history = data;
    },
    setSpecialisationsSelect(state, data) {
      state.specialisationsSelect = data;
    },
    setOfficeLevelsSelect(state, data) {
      state.officeLevelsSelect = data;
    },
    setOfficeRankingsSelect(state, data) {
      state.officeRankingsSelect = data;
    },
    setRegionsSelect(state, data) {
      state.regionsSelect = data;
    },
    setBusinessDevelopersSelect(state, data) {
      state.businessDevelopersSelect = data;
    },
    setFranchise(state, data) {
      state.franchise = data;
    },
    setOfficeStatistics(state, data) {
      state.officeStatistics = data;
    },
    setOfficeData(state, data) {
      state.office = data;
    },
    setCurrentOfficeExtendedData(state, data) {
      state.currentOfficeExtendedData = data;
    },
    setCurrentOfficeRegionCurrencyData(state, data){
      state.currentOfficeCurrencyData = data;
    },
    setOfficeLoading(state, loadingState) {
      state.loadingData = loadingState;
    },

    setRoles(state, data) {
      state.roles = data;
    },
    setRegionFiltersLoading(state, loadingState) {
      state.loadingFilters = loadingState;
    },
    setMainOfficeName(state, data) {
      state.mainOfficeName = data;
    },
    setOfficeSize(state, data) {
      state.officeSize = data;
    },
    setUngrouped(state, data) {
      state.ungrouped = data;
    },
	officeInfo(state, data) { state.officeInfo = data; },
	setOfficeInfoLoaded(state, data) { state.officeInfoLoaded = data; },
	regionInfo(state, data) { state.regionInfo = data; },
	contactInformationEntry(state, data) { state.contactInformationEntry = data; },
	phoneCodes(state, data) { state.phoneCodes = data; },
	phonecodesWithoutISO(state, data) { state.phonecodesWithoutISO = data; },
	countries(state, data) { state.countries = data; },
	isFranchiseAgreementCreate(state, data) { state.isFranchiseAgreementCreate = data; },
  },
  actions: {
	async officeInfo({ commit }, officeID) {
		try {
			let officeInfo = null;
			if (officeID) {
				const officeInfoRes = await BaseService.get('v1/office/getInfo', { officeID, });
				officeInfo = officeInfoRes?.data?.data;
			}
			commit('officeInfo', officeInfo);
		} catch(e) { /**/ }
	},

    async getAll({ commit, state }, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.offices.rowsPerPage;
        let currentPage = params.currentPage || state.offices.currentPage;
        if (!state.offices.totalPages || (currentPage < 1)) currentPage = 1;

        const res = await OfficeService.getAll({          
          ...params,
          currentPage,
          rowsPerPage,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setOffices', {
          list: res.data?.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch(e) { throw e; }
    },

    async getRegionsDropdown({ commit }) {
      try {
        const res = await OfficeService.getRegionsDropdown();
        commit('setRegionsDropdown', res.data?.result.dropdownListData);
        return res;
      } catch(e) { throw e; }
    },

    async getStatesDropdown({ commit }) {
      try {
        const res = await OfficeService.getOfficeRegionsDropdown();
        commit('setOfficeRegionsDropdown', res.data?.result);
        return res;
      } catch(e) { throw e; }
    },

    async getOffice({ commit }, office_id: string | number) {
      try {
		commit('setOfficeInfoLoaded', true);
        const res = await OfficeService.getOffice(office_id);
        const office = res?.data?.result || res.data || null;
		let officeInfo = null;
		let regionInfo = null;
		let contactInformationEntry = [];
		if (office) {
			const [
				contactInformationEntryRes,
				officeInfoRes,
				regionInfoRes,
				isFranchiseAgreementCreate,
			] = await promiseAll([
				OfficeEditorService.contactInformationEntry(office_id),
				BaseService.get('v1/office/getInfo', { officeID: office_id, }),
				BaseService.get('v1/region/getInfo', { regionID: office.regionID, }),
				OfficeEditorService.isFranchiseAgreementCreate(office_id),
			]);
			officeInfo = officeInfoRes?.data?.data;
			regionInfo = regionInfoRes?.data?.data;
			contactInformationEntry = contactInformationEntryRes?.data?.result || [];
			commit('isFranchiseAgreementCreate', isFranchiseAgreementCreate?.data?.result?.isFranchiseAgreementCreate || false);
		}
		commit('SET_CURRENT_OFFICE', office);
		commit('officeInfo', officeInfo);
		commit('regionInfo', regionInfo);
		commit('contactInformationEntry', contactInformationEntry);
        commit('setOfficeData', {
			...office,
			...officeInfo?.address, 
			street: office?.street || officeInfo?.address?.street,
			houseNumber: office?.houseNumber || officeInfo?.address?.houseNumber,
			additionalAddressInfo: office?.additionalAddressInfo || officeInfo?.address?.additionalAddressInfo,
			city: office?.city || officeInfo?.address?.city,
			zipCode: office?.zipcode || office?.zipCode || officeInfo?.address?.zipCode,
			zipcode: office?.zipcode || office?.zipCode || officeInfo?.address?.zipCode,
			countryISO: office?.countryISO || officeInfo?.address?.countryISO,
			latitude: office?.latitude || officeInfo?.address?.latitude,
			longitude: office?.longitude || officeInfo?.address?.longitude,
			latlongProcessed: office?.latlongProcessed || officeInfo?.address?.latlongProcessed,
		});
        return office;
      } catch (e) {
		commit('setOfficeInfoLoaded', true);
        throw e;
      } finally {
		commit('setOfficeInfoLoaded', true);
	  }
    },
	async countries({ commit }) { 
		const res = await OfficeEditorService.countries(); 
		commit('countries', res?.data?.result || []);
		return res;
	},
	async phoneCodes({ commit }) { 
		const res = await OfficeEditorService.phonecodes(); 
		commit('phoneCodes', res?.data?.result || []);
		return res;
	},
	async phonecodesWithoutISO({ commit }) { 
		const res = await OfficeEditorService.phonecodesWithoutISO(); 
		commit('phonecodesWithoutISO', res?.data?.result || []);
		return res;
	},
    async getCurrentOfficeExtendedData({ commit }, office_id: string | number) {
      try {
        const res = await OfficeService.getOfficeExtendedData(office_id);
        const data = res?.data.data || [];
        commit('setCurrentOfficeExtendedData', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    //get the office region currency
    async getCurrentOfficeRegionCurrencyData({ commit }, regionID: string | number) {
      try {
        const res = await OfficeEditorService.officeRegionCurrencyReporting(regionID);
        const data = res?.data;
        commit('setCurrentOfficeRegionCurrencyData', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getOfficeStatistics({ commit }, office_id: string | number) {
      try {
        const res = await OfficeService.getOfficeStatistics(office_id);
        const data = res?.data?.result || [];
        commit('setOfficeStatistics', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getFranchiseHistorySnapShots({ commit }, office_id: string | number) {
      try {
        const res = await OfficeService.getFranchiseHistorySnapShots(office_id);
        const data = res?.data?.result || [];
        commit('setFranchiseHistorySnapShots', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getFranchiseHistoryBySnapshot({ commit, state }, snapshot_id: string | number) {
      try {
        // const res = await OfficeService.getFranchiseHistoryBySnapshot(snapshot_id);
        // const data = res?.data?.result || [];
		const res = await BaseService.get(`/v1/office/getFranchiseAgreementInfo`, {
			franchiseAgreementID: snapshot_id,
			officeID: state.currentOffice?.ID,
		});
        const data = res?.data?.data || [];
        commit('setFranchiseHistoryBySnapShot', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getFranchiseHistorySegmentData({ commit }, snapshot_id: string | number) {
      try {
        const res = await OfficeService.getFranchiseHistorySegmentData(snapshot_id);
        const data = res?.data?.result || [];
        commit('setFranchiseSegmentData', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getInitialSegmentHistory({ commit }, snapshot_id: string | number) {
      try {
        const res = await OfficeService.getInitialSegmentHistory(snapshot_id);
        const data = res?.data?.result || [];
        commit('setInitialSegmentHistory', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getInitialFranchiseData({ commit }, snapshot_id: string | number) {
      try {
        const res = await OfficeService.getInitialFranchiseData(snapshot_id);
        const data = res?.data?.result || [];
        commit('setInitialHistory', data);
        return data;
      } catch (e) {
        throw e;
      }
    },

    getAllWithFilter({ commit }, FilterTerm) {
      return OfficeService.getAllWithFilter(FilterTerm).then((res) => commit('SET_OFFICE_LIST', res.data?.result));
    },
    async getStatus({ commit }) {
      commit('setStatusFiltersLoading', true);
      const res = await OfficeService.getStatus();
      let tagArray = [];
      let filters = [];
      //map result with label:, label is needed by LookupFilterComponent
      tagArray = res.data?.result.map((r) => ({
        label: r.name,
      }));
      //Sort by label
      filters = tagArray.sort((n1, n2) => {
        if (n1.label > n2.label) {
          return 1;
        }

        if (n1.label < n2.label) {
          return -1;
        }

        return 0;
      });
      const statusFilters = [
        {
          name: 'Status',
          options: filters,
        },
      ];
      commit('setRegionFiltersLoading', false);
      return statusFilters;
    },
    async getById({ commit }, office_id) {
      try {
        commit('setLoadingCurrentOffice', true);
        const res = await OfficeService.get(`office/${office_id}`);
        if (res.data?.result === undefined) {
          // let ytd = await store.dispatch('cube/getOfficeYTDGCI', { officeID: res.data.ID });
          // if (ytd) {
          //   res.data.gciSumYear = Number(ytd['PerformanceCube.gciForPeriodEur']);
          // }
          commit('SET_CURRENT_OFFICE', res.data);
        } else {
          // let ytd = await store.dispatch('cube/getOfficeYTDGCI', { officeID: res.data.result.ID });
          // if (ytd) {
          //   res.data.result.gciSumYear = Number(ytd['PerformanceCube.gciForPeriodEur']);
          // }
          commit('SET_CURRENT_OFFICE', res.data?.result);
        }
        commit('setLoadingCurrentOffice', false);
        return res;
      } catch (e) {
        commit('setLoadingCurrentOffice', false);
        throw e;
      }
    },
    // Takes in main office id - not current office id
    async getMainOffices({ commit }, office_id) {
      const res = await OfficeService.getById(office_id);
      commit('setMainOfficeName', res.data?.result);
      return res;
    },
    getOfficeList({ commit }) {
      return OfficeService.getOfficeList().then((res) => commit('SET_OFFICE_LIST', res.data?.result));
    },
    // async getAll({ commit }) {
    //   try {
    //     commit('setOfficeLoading', true);
    //     const res = await OfficeService.getAll();
    //     commit('SET_OFFICE_LIST', res.data?.result);
    //     commit('setOfficeLoading', false);
    //     return res;
    //   } catch (e) {
    //     commit('setOfficeLoading', false);
    //     throw e;
    //   }
    // },
    deleteOne({ commit }, payload) {
      return OfficeService.remove(payload);
    },
    create({ commit }, payload) {
      return OfficeService.create(payload);
    },
    async getPersonsTable({ commit, state }, params: { office_id: string | number; only_active?: boolean }) {
      commit('setLoadingPersonsList', true);
      commit('setActivePersonsListOnly', params.only_active);
      try {
        const res = await OfficeService.getPersonsTable(params.office_id, params.only_active);
        const rowsPerPage = state.personsList.rowsPerPage;
        let currentPage = state.personsList.currentPage;

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setPersonsList', {
          list: res.data?.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        commit('setLoadingPersonsList', false);
      } catch (e) {
        commit('setLoadingPersonsList', false);
        throw e;
      }
    },
    async getOfficeTeamsTable({ commit }, params: { office_id: string | number; only_active?: boolean }) {
      commit('setLoadingOfficeTeamsTable', true);
      commit('setActiveOfficeTeams', params.only_active);
      try {
        const data = await OfficeService.getOfficeTeamsTable(params.office_id, params.only_active);
        commit('setOfficeTeamsList', data.data.result);
        commit('setLoadingOfficeTeamsTable', false);
      } catch (e) {
        commit('setLoadingOfficeTeamsTable', false);
        throw e;
      }
    },
    async getSnapshotDropdown({ commit }, params: { office_id: string | number }) {
      try {
        const res = await OfficeService.getSnapshotDropdown(params?.office_id);
        const data = res?.data?.result || [];
        commit('setSnapshotDropdown', data);
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getOfficeShareSnapshotData({ commit }, params: { snapshot_id: string | number }) {
      try {
        commit('setLoadingOfficeShareSnapshotData', true);
        const res = await OfficeService.getOfficeShareSnapshotData(params?.snapshot_id);
        const data = res?.data?.result || [];
        commit('setOfficeShareSnapshotData', data);
        commit('setLoadingOfficeShareSnapshotData', false);
        return res;
      } catch (e) {
        commit('setLoadingOfficeShareSnapshotData', false);
        throw e;
      }
    },
    async officeShareSnapshotStep1Validation({ commit }, params) {
      try {
        const res = await OfficeService.officeShareSnapshotStep1Validation(params);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async officeShareSnapshotStep2Validation({ commit }, params) {
      try {
        const res = await OfficeService.officeShareSnapshotStep2Validation(params);
        return res;
      } catch (e) {
        throw e;
      }
    },
    getOwners({ commit }, params: { office_id: string | number }) {
      commit('setLoadingOwners', true);
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.owners;
          commit('setOwners', data);
          commit('setLoadingOwners', false);
          resolve(data);
        }, 1500)
      );
    },
    getOwnerTypes({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.ownerTypes;
          commit('setOwnerTypes', data);
          resolve(data);
        }, 1500)
      );
    },
    getOwnershipTypes({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.ownershipTypes;
          commit('setOwnershipTypes', data);
          resolve(data);
        }, 1500)
      );
    },
    async getGroupsInRegion({ commit }, params: { office_id: string | number }) {
      let data = [];
      try {
        const res = await OfficeService.getGroupsInRegion(params?.office_id);
        data = res?.data?.result || [];
        commit('setGroupsInRegion', data);
      } catch (e) {
        throw e;
      }
      return data;
    },
    // async getPersonsTable({ commit }, params: { office_id: string | number; only_active?: boolean }) {
    //   commit('setLoadingPersonsList', true);
    //   commit('setActivePersonsListOnly', params.only_active);
    async getOfficeGroups({ commit }, params: { remax_entity_id: string | number; only_active?: boolean }) {
      //
      try {
        commit('setLoadingOfficeGroups', true);
        commit('setActiveOfficeGroupListOnly', params.only_active);
        const res = await OfficeService.getOfficeGroups(params);
        commit('setOfficeGroups', res.data?.result);
        commit('setLoadingOfficeGroups', false);
        return res;
      } catch (e) {
        commit('setLoadingOfficeGroups', false);
        throw e;
      }
    },
    async getOfficeContactInformationList({ commit }, params: { office_id: string | number }) {
      let data = [];
      try {
        const res = await OfficeService.selectOfficeContactInformationList(params?.office_id);
        data = res?.data?.result || [];
        commit('setContactInformationList', data);
      } catch (e) {
        throw e;
      }
      return data;
    },
    async getFranchise({ commit }, { office_id }) {
      try {
        const res = await OfficeService.getFranchise(office_id);
        const franchise = res?.data?.result;
        commit('setFranchise', franchise);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getFranchiseSalesReps({ commit }, region_id) {
      try {
        const res = await OfficeService.getRegionFranchiseSalesReps(region_id);
        const data = res?.data?.result || [];
        commit(
          'setFranchiseSalesReps',
          data.map((r) => ({
            data: r,
            value: r.personID,
            label: `${[
              `${r.firstName || ''} ${r.lastName || ''}`.trim(),
              r.uniquePersonID,
              r.primaryTitle,
              r.primaryOfficeName,
            ]
              .filter((s) => s)
              .join(', ')}${r.personIsActive ? '' : ' - inactive'}`,
          }))
        );
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getFranchiseSizeSelect({ commit }, { office_id }) {
      try {
        const res = await OfficeService.franchiseAgreementSegments(office_id);
        commit(
          'setFranchiseSizeSelect',
          (res?.data?.result || []).map((s) => ({
            label: s.name,
            value: s.ID,
          }))
        );
        return res;
      } catch (e) {
        throw e;
      }
    },
    async saveFranchise({ commit }, data) {
      try {
        const res = await OfficeService.saveFranchise(data);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getDevelopmentSchedules({ commit }, params: { office_id: string | number }) {
      let data = [];
      try {
        const res = await OfficeService.getDevelopmentSchedules(params.office_id);
        data = res.data?.result || [];
        commit('setDevelopmentSchedules', data);
      } catch (e) {
        throw e;
      }
      return data;
    },
    async getDevelopmentSchedulesBySnapshot({ commit }, params: { office_id: string | number; currentSnap }) {
      let data = [];
      try {
        const res = await OfficeService.getDevelopmentSchedulesBySnapshot(params.office_id, params.currentSnap);
        data = res.data?.result || [];
        commit('setDevelopmentSchedules', data);
      } catch (e) {
        throw e;
      }
      return data;
    },

    async getDevelopmentSchedulesBySnapshot2({ commit }, params: { office_id: string | number; currentSnap }) {
      let data = [];
      try {
        const res = await OfficeService.getDevelopmentSchedulesBySnapshot2(params.office_id, params.currentSnap);
        data = res.data?.result || [];
        commit('setDevelopmentSchedules2', data);
      } catch (e) {
        throw e;
      }
      return data;
    },

    async insertDevelopmentSchedules({ commit, state }, officeID) {
      try {
        await OfficeService.insertDevelopmentSchedules(officeID, state.developmentSchedules);
      } catch (e) {
        throw e;
      }
    },
    async getDevelopmentSchedulesSnapshot({ commit }, params: { office_id: string | number }) {
      const res = await OfficeService.getDevelopmentSchedulesSnapshot(params.office_id);
      commit('setDevelopmentSchedulesSnapShot', res.data?.result);
    },
    async getNoteList({ commit }, params) {
      const res = await OfficeService.getNoteList(params.office_id);
      commit('SET_NOTE_LIST', res.data?.result);
      return res;
    },
    async getOfficeHistory({ commit }, params) {
    //   const res = await OfficeService.getOfficeHistory(params.office_id);
    //   commit('SET_HISTORY', res.data?.result);

	  const res = await BaseService.get('v1/office/getHistory', { officeID: params.office_id });
	  commit('SET_HISTORY', res.data?.data);

      return res;
    },
    getSpecialisationsSelect({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.specialisationsSelect;
          commit('setSpecialisationsSelect', data);
          resolve(data);
        }, 1500)
      );
    },
    getOfficeLevelsSelect({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.officeLevelsSelect;
          commit('setOfficeLevelsSelect', data);
          resolve(data);
        }, 1500)
      );
    },
    async getOfficeRankingsSelect({ commit }) {
      try {
        const res = await OfficeService.officeRankingLabels();
        const data = res.data?.result || [];
        commit(
          'setOfficeRankingsSelect',
          data.map((r) => ({
            label: r.entryValue,
            value: r.entryKey,
          }))
        );
        return res;
      } catch (e) {
        throw e;
      }
    },
    getRegionsSelect({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.regionsSelect;
          commit('setRegionsSelect', data);
          resolve(data);
        }, 1500)
      );
    },
    getBusinessDevelopersSelect({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.businessDevelopersSelect;
          commit('setBusinessDevelopersSelect', data);
          resolve(data);
        }, 1500)
      );
    },
    async deleteOfficeFromGroup({ commit, state }, params) {
      try {
        const res = await OfficeService.deleteOfficeFromGroup(params);
        const res2 = await OfficeService.getOfficeGroups({ entityID: state.currentOffice.remaxEntityID });
        commit('setOfficeGroups', res2.data.result);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async saveOfficeToGroup({ commit, state }, params) {
      try {
        let res = null;
        if (params.id) {
          res = await OfficeService.updateOfficeGroup(params);
        } else {
          res = await OfficeService.addOfficeToGroup(params);
        }
        const res2 = await OfficeService.getOfficeGroups({ entityID: state.currentOffice.remaxEntityID });
        commit('setOfficeGroups', res2.data.result);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async saveNote({ commit }, params: { office_id: string | number; entity: any }) {
      let res = null;
      if (params.entity.ID) {
        res = await OfficeService.updateNote(params.entity, params.office_id);
        //commit('updateNote', res.data?.result.model);
      } else {
        res = await OfficeService.insertNote(params.entity, params.office_id);
        //commit('insertNote', res.data?.result.model);
      }
      const listRes = await OfficeService.getNoteList(params.office_id);
      commit('SET_NOTE_LIST', listRes.data.result);
      return res;
    },
    async deleteNote({ commit }, params: { office_id: string | number; note_id: string | number }) {
      await OfficeService.deleteNote(params.note_id);
      const listRes = await OfficeService.getNoteList(params.office_id);
      commit('SET_NOTE_LIST', listRes.data.result);
    },
    async insertStaff({ commit }, params: { office_id: string | number; entity: any }) {
      await OfficeService.insertStaff(params.entity, params.office_id);
    },
    async insertAgent({ commit }, params: { office_id: string | number; entity: any }) {
      await OfficeService.insertAgent(params.entity, params.office_id);
    },

    async saveOffice({ commit }, params) {
      commit('setSavingOffice', true);
      let data = null;
      try {
        if (params.ID) {
          const res = await OfficeService.update(params.ID, params);
          // commit('SET_CURRENT_OFFICE', res.data?.result);
          data = res;
        } else {
          delete params.ID;
          const res = await OfficeService.create(params);
          data = res?.data?.result?.model;
        }
        commit('setSavingOffice', false);
      } catch (e) {
        commit('setSavingOffice', false);
        throw e;
      }
      return data;
    },

    async getOfficesDropdown({ commit }, { region_id, ...query }) {
      try {
        const res = await OfficeService.getOfficesDropdown(region_id, query);
        const data = res.data?.result || [];
        commit('setOfficesDropdown', data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRoles({ commit }, office_id) {
      try {
        const res = await OfficeService.getRoles(office_id);
        const data = res.data?.result || [];
        commit('setRoles', data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getUngrouped({ commit },regionID) {
      try {
        const res = await OfficeGroupEditorService.getUngrouped(regionID);
        const data = res.data?.result || [];
        commit('setUngrouped', data);
        return res;
      } catch (e) {
        throw e;
      }
    }
  },
};
