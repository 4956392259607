import { AffiliateDashboardService } from '@/services/modules/affiliateDashboard.service';
import { BaseService } from '@/services/base.service';

export default {
  namespaced: true,
  state: {
    pieChart: [],
    productivityChart: [],
    averageTransactionsByAgentData: [],
    agentAverageTransactionsPerTenureGroupData: [],
  },
  getters: {
    getAgentCountData: (state) => state.pieChart,
  },
  mutations: {
    setAgentCountData(state, data) {
      state.pieChart = data;
    },
    setAgentProductivityData(state, data) {
      state.productivityChart = data;
    },
    setAverageTransactionsPerYearData(state, data) {
      state.averageTransactionsByAgentData = data;
    },
    setAgentAverageTransactionsPerTenureGroupData(state, data) {
      state.agentAverageTransactionsPerTenureGroupData = data;
    },
  },
  actions: {
    async getAgentCountData({ commit, state }, params) {
      const res = await AffiliateDashboardService.getAgentCountData(params);
      commit('setAgentCountData', res.data.data.result);
      return res;
    },
    async getAgentProductivityData({ commit, state }, params) {
      const res = await AffiliateDashboardService.getAgentProductivityData(params);
      commit('setAgentProductivityData', res.data.data.result);
      return res;
    },
    async getAverageTransactionsPerYearData({ commit, state }, params) {
      const res = await AffiliateDashboardService.getAverageTransactionsPerYearData(params);
      commit('setAverageTransactionsPerYearData', res.data.data.result);
      return res;
    },
    async getAgentAverageTransactionsPerTenureGroup({ commit, state }, params) {
      const res = await AffiliateDashboardService.getAgentAverageTransactionsPerTenureGroup(params);
      commit('setAgentAverageTransactionsPerTenureGroupData', res.data.data.result);
      return res;
    },
  },
};
