import { promiseAll } from '@/utils/promiseAll';
import { OfficeGroupEditorService } from '../../../services/modules/officeGroupsEditor.service';

export async function init(store, groupID) {
	groupID = groupID || store.state.group?.ID;
	try {
		await promiseAll([
			group(store, groupID),
			//groups(store, {}),
			changeHistory(store, {}, groupID),
		]);
	} catch(e) { console.log(e); throw e; }
}

export async function group(store, groupID, params = {}) {
	try {
		if (groupID) {
			const [
				group,
			] = await promiseAll([
				OfficeGroupEditorService.group(groupID),
				entities(store, {}, groupID),
			]);
			
			if(group?.data?.result == undefined || group?.data?.result == null) {
				store.commit('setState', {
					group: group?.data
				});
			}
			else{
				store.commit('setState', {
					group: group?.data?.result
				});
			}	
			
		}
	} catch(e) { console.log(e); /**/ }
}

export async function groups({ commit, state }, officeID) {
	try {
		commit('setState', { groups: { ...state.groups, loading: true}, });

		const res = await OfficeGroupEditorService.get(`office/${officeID}`);
		commit('setState', {
			selected: [],
			groups: {
				officeID: officeID,
				loading: false,
				list: res?.data?.result || []
			},
		});
	} catch(e) { 
		commit('setState', { groups: { ...state.groups, loading: false, }, });
		throw e; 
	}
}

export async function entities({ commit, state }, params = {}, groupID?: any) {
	try {
		groupID = state.group?.ID || groupID;
		if (groupID) {
			try {
				const { queryParams, ..._params } = { ...params, } as any;
				const qParams = { ...state.entities.queryParams, ...queryParams, };

				commit('setState', { entities: { ...state.entities, loading: true, queryParams: qParams, }, });

				const res = await OfficeGroupEditorService.entities(groupID, { ...qParams, });
				commit('setState', {
					entities: {
						..._params,
						loading: false,
						list: res?.data?.result || [],
						queryParams: qParams,
					},
				});
				const ungrouped = await OfficeGroupEditorService.ungrouped();
				commit('setState', {
					ungrouped: {
						loading: false,
						list: ungrouped?.data?.result || [],
					},
				});
			} catch(e) { 
				commit('setState', { entities: { ...state.entities, loading: false, }, });
				throw e; 
			}
		}
	} catch(e) { console.log(e); /**/ }
}

export async function ungrouped({ commit, state }, regionID) {
	try {
		const res = await OfficeGroupEditorService.ungrouped(regionID);
		commit('setState', {
			ungrouped: {
				loading: false,
				list: res?.data?.result || [],
			},
		});
	} catch(e) { 
		commit('setState', { ungrouped: { ...state.ungrouped, loading: false, }, });
		throw e; 
	}
}


export async function currentEntities({ commit, state }, params = {}, groupID?: any) {
	try {
		groupID = state.group?.ID || groupID;
		if (groupID) {
			try {
				const { queryParams, ..._params } = { ...params, } as any;
				const qParams = { ...state.entities.queryParams, ...queryParams, };

				commit('setState', { entities: { ...state.entities, loading: true, queryParams: qParams, }, });

				const res = await OfficeGroupEditorService.entities(groupID, { ...qParams, });
				commit('setState', {
					entities: {
						..._params,
						loading: false,
						list: res?.data?.result || [],
						queryParams: qParams,
					},
				});
			} catch(e) { 
				commit('setState', { entities: { ...state.entities, loading: false, }, });
				throw e; 
			}
		}
	} catch(e) { console.log(e); /**/ }
}

export async function changeHistory({ commit, state }, params = {}, groupID?: any) {	
	try {
		groupID = state.group?.ID || groupID;
		if (groupID) {
			const { queryParams, ..._params } = { ...params, } as any;
			const currentPage = _params.currentPage || 1;
			const rowsPerPage = _params.rowsPerPage || 35;
			const qParams = { ...state.history.queryParams, ...queryParams, };

			commit('setState', { history: { ...state.history, loading: true, queryParams: qParams, }, });

			const res = await OfficeGroupEditorService.history(groupID, {
				...qParams,
				//MAX-1129
				//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
			});
			const totalRows = res?.data?.info?.numRows || 0;
			commit('setState', {
				history: {
					..._params,
					currentPage,
					rowsPerPage,
					loading: false,
					totalRows,
					totalPages: Math.ceil(totalRows / rowsPerPage),
					list: res?.data?.result || [],
					queryParams: qParams,
				},
			});
		}
	} catch(e) { 
		console.log(e);
		commit('setState', { history: { ...state.history, loading: false, }, });
		throw e; 
	}
}
