import { CONTACT_TYPE_MAP } from '@/constants';
import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, initParams: any = {}) {
	const { commit } = store;
	commit('setState', { initParams });
	try {
		const res = await promiseAll([
			contact(store, {}, initParams),		
			BaseService.phonecodesWithoutISO(),
			BaseService.phonecodes(),
			BaseService.contactInformationEntryCategories({ 'textFilter[isPhoneType]': 1, }),
			BaseService.contactInformationEntryCategories({ 'textFilter[isEmailType]': 1, }),
			BaseService.contactInformationEntryTypes(),
			BaseService.countries(),
		]);
		commit('setState', {
			phonecodesWithoutISO: res[1]?.data?.result || [],
			phonecodes: res[2]?.data?.result || [],
			phoneCategories: res[3]?.data?.result || [],
			emailCategories: res[4]?.data?.result || [],
			contactInformationEntryTypes: res[5]?.data?.result || [],
			countries: res[6]?.data?.result || [],
		});
	} catch(e) { console.log(e); throw e; }
}

export async function contact(store, params = {}, initParams?: any) {
	const { contactType, } = initParams || store.state.initParams;
	if (CONTACT_TYPE_MAP[contactType] === CONTACT_TYPE_MAP.person) await person(store, {},initParams);
	if (CONTACT_TYPE_MAP[contactType] === CONTACT_TYPE_MAP.company) await company(store, {},initParams);	
}

export async function person(store, params = {}, initParams?: any) {
	const { contactID, contactType, } = initParams || store.state.initParams;
	try {
		if (contactID) {
			const [personRes] = await promiseAll([
				BaseService.get(`contactPerson/${contactID}`, { contactType: CONTACT_TYPE_MAP[contactType], }),
				changeHistory(store, {}, initParams),
			]);

			const person = personRes?.data?.result;

			let company = null;
			if (person?.contactCompanyID) {
				const companyRes = await BaseService.get(`contactCompany/${person?.contactCompanyID}`);
				company = companyRes?.data?.result;
			}
	
			store.commit('setState', {
				person,
				company,
			});
		}
	} catch(e) { console.log(e); /**/ }
}

export async function company(store, params = {}, initParams?: any) {
	const { contactID, } = initParams || store.state.initParams;
	try {
		if (contactID) {
			const [company] = await promiseAll([
				BaseService.get(`contactCompany/${contactID}`),
				changeHistory(store, {}, initParams),
			]);
	
			store.commit('setState', {
				company: company?.data?.result,
			});
		}
	} catch(e) { console.log(e); /**/ }
}

export async function changeHistory({ commit, state }, params = {}, initParams?: any) {	
	try {
		const { contactID, contactType, } = initParams || state.initParams;
		if (contactID) {
			const { queryParams, ..._params } = { ...params, } as any;
			const currentPage = _params.currentPage || 1;
			const rowsPerPage = _params.rowsPerPage || 35;
			const qParams = { ...state.history.queryParams, ...queryParams, };

			commit('setState', { history: { ...state.history, loading: true, queryParams: qParams, }, });

			const res = await BaseService.get(`contactManagement/${contactID}/editHistory`, {
				...qParams,
				'metaData[contactType]': CONTACT_TYPE_MAP[contactType],
				//MAX-1129
				//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
			});
			const totalRows = res?.data?.info?.numRows || 0;
			commit('setState', {
				history: {
					..._params,
					currentPage,
					rowsPerPage,
					loading: false,
					totalRows,
					totalPages: Math.ceil(totalRows / rowsPerPage),
					list: res?.data?.result || [],
					queryParams: qParams,
				},
			});
		}
	} catch(e) { 
		console.log(e);
		commit('setState', { history: { ...state.history, loading: false, }, });
		throw e; 
	}
}
