import moment from 'moment';
const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
import { normalizeOfficeStatus, normalizeOfficeStatusWithColor } from '@/components/editor/reports/helpers';

export default {
  region: {
    data: 'region',
    name: 'Region',
    link: { route: 'Edit Region', id: 'regionid', paramKey: 'region_id' },
  },
  subRegion: {
    data: 'subregionname',
    name: 'Region/Subregion',
  },
  masterPersonIDRecurring: {
    data: 'master_person_id',
    name: 'Associate ID',
    transformHTML: (value) => {
      return value ? value.toString() : '-';
    },
  },
  statisticDate: {
    data: 'statistics_date',
    name: 'Statistics Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  statisticType: {
    data: 'statistics_type',
    name: 'Statistics Type',
  },
  statisticScope: {
    data: 'statistics_scope',
    name: 'Statistics Scope',
  },
  commissionAmount: {
    data: 'commission_amount',
    name: 'Commission Amount',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  listing: {
    data: 'listing',
    name: 'Listing',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  listingAmount: {
    data: 'listing_amount',
    name: 'Listing Amount',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  officeMasterId: {
    data: 'master_office_id',
    name: 'Office ID',
  },
  sale: {
    data: 'sale',
    name: 'Sale',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  saleAmount: {
    data: 'sale_amount',
    name: 'Sale Amount',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  firstName: {
    data: 'firstname',
    name: 'First Name',
    link: { route: 'ViewPerson', id: 'idperson', paramKey: 'id' },
  },
  firstNameDailyNewMembers: {
    data: 'firstname',
    name: 'First Name',
    link: { route: 'ViewPerson', id: 'person_id', paramKey: 'id' },
  },
  lastNameDailyNewMembers: {
    data: 'lastname',
    name: 'Last Name',
    link: { route: 'ViewPerson', id: 'person_id', paramKey: 'id' },
  },
  lastName: {
    data: 'lastname',
    name: 'Last Name',
    link: { route: 'ViewPerson', id: 'idperson', paramKey: 'id' },
  },
  associateName: {
    data: 'person_name',
    name: 'Associate Name',
    link: { route: 'ViewPerson', id: 'person_id', paramKey: 'id' },
  },
  uniqueID: {
    data: 'uniquepersonid',
    name: 'Person Unique ID',
  },
  status: {
    data: 'isactive',
    name: 'Current Status',
    transformHTML: (value) => {
      if (value === 1) {
        return '<div class="text-green-500">Active</div>';
      }
      return '<div class="text-red-500">Inactive</div>';
    },
    transformReport: (value) => {
      if (value === 1) {
        return 'Active';
      }
      return 'Inactive';
    },
  },
  statusAgents: {
    data: 'isactive',
    name: 'Current Status',
    transformHTML: (value) => {
      if (value === 1) {
        return '<div class="text-green-500">Active</div>';
      }
      return '<div class="text-red-500">Inactive</div>';
    },
    transformReport: (value) => {
      if (value === 1) {
        return 'Active';
      }
      return 'Inactive';
    },
  },
  officeName: {
    data: 'officename',
    name: 'Office Name',
    link: { route: 'ViewOffice', id: 'officeid', paramKey: 'office_id' },
  },
  primaryOfficeName: {
    data: 'primaryofficename',
    name: 'Office Name',
    link: { route: 'ViewOffice', id: 'officeid', paramKey: 'office_id' },
  },
  mainOfficeName: {
    data: 'mainofficename',
    name: 'Office Name',
    link: { route: 'ViewOffice', id: 'mainOfficeId', paramKey: 'office_id' },
  },
  officeUniqueID: {
    data: 'uniqueofficeid',
    name: 'Office Unique ID',
  },
  personOfficeUniqueID: {
    data: 'officeUniqueid',
    name: 'Office Unique ID',
  },
  officeUniqueIDByPerson: {
    data: 'officeUniqueid',
    name: 'Office Unique ID',
    transformHTML: (value) => {
      if (value) {
        return value.length > 5 ? value : '-';
      }
      return '-';
    },
  },
  SatelliteOffice: {
    data: 'isSatelliteOffice',
    name: 'Satellite Office',
    transformHTML: (value) => {
      if (value === 'Satellite') {
        return 'Yes';
      }
      return 'No';
    },
  },
  primaryTitle: {
    data: 'primarytitle',
    name: 'Primary Title',
  },
  title: {
    data: 'title',
    name: 'Title [in Office]',
  },
  titleInOffice: {
    data: 'display_title',
    name: 'Title [in Office]',
  },
  primaryValidFrom: {
    data: 'primarytitlevalidfrom',
    name: 'Primary Valid From',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  primaryValidTo: {
    data: 'primarytitlevalidto',
    name: 'Primary Valid To',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  terminationReason: {
    data: 'terminationreason',
    name: 'Termination Reason',
    transformHTML: (value) => {
      return value ? value : '-';
    },
  },
  terminations: {
    data: 'terminationreason',
    name: 'Terminations',
  },
  remaxCommenceDate: {
    data: 'remaxcommencedate',
    name: 'RE/MAX Commence Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  addDate: {
    data: 'addate',
    name: 'Annual Dues Anniversarys',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  tenureDays: {
    data: 'tenuredays',
    name: 'Tenure',
    transformHTML: (value) => {
      return value ? (value / 365).toFixed(2) : '-';
    },
    transformReport: (value) => {
      return value ? parseFloat((value / 365).toFixed(2)) : '-';
    },
  },
  teamStatus: {
    data: 'CurrentTeamStatus',
    name: 'Team Status',
    transformHTML: (value) => {
      return value === 'I' ? 'Individual' : '-';
    },
  },
  teamStatusAgents: {
    data: 'CurrentTeamStatus',
    name: 'Team Status',
  },
  teamStatusTeamAggregated: {
    data: 'teamStatus',
    name: 'Team Status',
    transformHTML: (value) => {
      return value === 'inactive' ? normalizeOfficeStatusWithColor('Inactive') : normalizeOfficeStatusWithColor('Active');
    },
    transformReport: (value) => {
      if (!value) return '-';
      return value === 'inactive' ? 'Inactive' : 'Active';
    },
  },
  teamNameStatus: {
    data: 'CurrentTeamStatus',
    name: 'Team Name',
    transformHTML: (value) => {
      return value === 'I' ? 'Individual' : '-';
    },
  },
  teamName: {
    data: 'teamname',
    name: 'Team Name',
    link: { route: 'ViewTeam', id: 'teamid', paramKey: 'team_id' },
    transformHTML: (value) => {
      return value ? value : null;
    },
  },
  teamNameMonthly: {
    data: 'team_name',
    name: 'Team Name',
    link: { route: 'ViewTeam', id: 'team_id', paramKey: 'team_id' },
    transformHTML: (value) => {
      return value ? value : null;
    },
  },
  teamIdMonthly: {
    data: 'team_llc_id',
    name: 'Team ID',
  },
  teamNamePerformancePerTeam: {
    data: 'TeamName',
    name: 'Team Name',
    link: { route: 'ViewTeam', id: 'TeamID', paramKey: 'team_id' },
    transformHTML: (value) => {
      return value ? value : null;
    },
  },
  teamNamePerformanceAggregated: {
    data: 'teamname',
    name: 'Team Name',
    link: { route: 'ViewTeam', id: 'teamid', paramKey: 'team_id' },
    transformHTML: (value) => {
      return value ? value : null;
    },
  },
  teamID: {
    data: 'teamid',
    name: 'Team ID',
  },
  teamLeaderName: {
    data: 'team_leader_name',
    name: 'Team Leader Name',
  },
  teamLeaderNameJoined: {
    data: 'lastname',
    name: 'Team Leader',
    link: { route: 'ViewPerson', id: 'personid', paramKey: 'id' },
    transformHTML: (value) => {
      return value ? value : null;
    },
  },
  teamStatusDate: {
    data: 'teamStatusDate',
    name: 'Team Status Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  teamllcid: {
    data: 'teamllcid',
    name: 'Team LLC ID',
  },
  specialization: {
    data: 'specializationname',
    name: 'Specialization',
  },
  officeSpecialization: {
    data: 'specializationnames',
    name: 'Specialization',
    transformHTML: (value) => {
      if (!value) return '-';
      let returnStr = '';
      returnStr = value.replace(/[{""}]/g, '');
      return returnStr;
    },
  },
  gender: {
    data: 'gender',
    name: 'Gender',
    transformHTML: (value) => {
      return value === 'M' ? 'Male' : 'Female';
    },
  },
  genderAgent: {
    data: 'gender',
    name: 'Gender',
    transformHTML: (value) => {
      return value ? 'Female' : 'Male';
    },
  },
  birthdate: {
    data: 'birthdate',
    name: 'Date of Birth',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  email: {
    data: 'email',
    name: 'E-Mail',
  },
  socialSecurityNumber: {
    data: 'socialsecuritynumber',
    name: 'Social Security Number',
  },
  address: {
    data: 'totaladdress',
    name: 'Address',
    transformHTML: (value) => {
      return /^\s/.test(value) ? '-' : value;
    },
  },
  phone: {
    data: 'phone',
    name: 'Phone',
    transformHTML: (value) => {
      return value !== '+  ' ? value : '-';
    },
  },
  rankingsAwardsExcluded: {
    data: 'awardsrankingsexcluded',
    name: 'Rankings and Awards Excluded',
    transformHTML: (value) => {
      return value ? 'Yes' : 'No';
    },
  },
  highestCareerAward: {
    data: 'highestcareeraward',
    name: 'Highest Career Award',
  },
  highestClubAward: {
    data: 'highestclubaward',
    name: 'Highest Club Award',
  },
  careerGCI: {
    data: 'gciForPeriod',
    name: 'Career GCI',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      return currency + ' ' + formatter.format(value);
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  careerGCIAgents: {
    data: 'careerGCI',
    name: 'Career GCI',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      return currency + ' ' + formatter.format(value);
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  gciPeriod: {
    data: 'sumCommission',
    euro: 'sumCommission',
    dollar: 'sumCommission',
    type: 'int',
    local: 'sumCommission',
    name: 'GCI',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(formatter.format(value));
    },
  },
  gciPeriodStandard: {
    data: 'gciForPeriod',
    euro: 'gciForPeriod',
    dollar: 'gciForPeriod',
    local: 'gciForPeriod',
    name: 'GCI',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  volumePeriodStandard: {
    data: 'volumeForPeriod',
    name: 'Volumes',
    euro: 'volumeForPeriod',
    dollar: 'volumeForPeriod',
    local: 'volumeForPeriod',
    type: 'int',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  transactionsPeriodStandard: {
    data: 'transactionForPeriod',
    name: 'Transactions',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  gciYearly: {
    data: 'gciForPeriod',
    local: 'gciForPeriod',
    name: `${moment().year()} GCI`,
    type: 'int',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (!value) return currency + ' ' + formatter.format(0);
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  gciCareer: {
    data: 'gciCareer',
    local: 'gciCareer',
    name: `Career GCI`,
    currency: true,
    transformHTML: (value, currency = null) => {
      if (!value) return currency + ' ' + formatter.format(0);
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  transactionsPeriod: {
    data: 'sumTransaction',
    name: 'Transactions',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(formatter.format(value));
    },
  },
  volumePeriod: {
    data: 'sumVolume',
    name: 'Volumes',
    euro: 'sumVolume',
    dollar: 'sumVolume',
    local: 'sumVolume',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(formatter.format(value));
    },
  },
  reportingCurrencyISO: {
    data: 'reportingcurrencyiso',
    name: 'Reporting Currency',
  },
  officesOwnedByName: {
    data: 'offices_owned',
    name: 'Offices Owned by name',
  },
  officesOwnedByUniqueID: {
    data: 'offices_owned_unique_ids',
    name: 'Offices Owned by Office Unique ID',
  },
  fullLocalName: {
    data: 'fulllocalname',
    name: 'Full Name (Local Language)',
  },
  displayName: {
    data: 'marketingname',
    name: 'Display Name',
  },
  teamLeaderID: {
    data: 'uniquepersonid',
    name: 'Team Leader ID',
  },
  teamMemberCount: {
    data: 'teammembercount',
    name: 'Current Member Count',
    transformHTML: (value) => {
      if (value) return value;
      return 0;
    },
  },
  teamSize: {
    data: 'teamsize',
    name: 'Current Team Size',
  },
  academicTitle: {
    data: 'title',
    name: 'Academic Title',
  },
  suffixName: {
    data: 'suffix_name',
    name: 'Suffix',
  },
  displayTitle: {
    data: 'display_title',
    name: 'Display Title',
  },
  legacyMemberID: {
    data: 'remaxpersonid',
    name: 'Legacy Member ID',
  },
  idllPerson: {
    data: 'idllcperson',
    name: 'Person Constituent ID',
    transformReport: (value) => {
      if (value) return value.toString();
      return '-';
    },
  },
  externalPersonID: {
    data: 'externalpersonid',
    name: 'External ID',
  },
  internalPersonID: {
    data: 'internalpersonid',
    name: 'Internal Member ID',
  },
  officeConstituentID: {
    data: 'officeConstituentId',
    name: 'Office Constituent ID',
  },
  personOfficeConstituentID: {
    data: 'officeconstituentid',
    name: 'Office Constituent ID',
  },
  officeConstituentIDCombinedReport: {
    data: 'officeconstituentid',
    name: 'Office Constituent ID',
  },
  personConstituentID: {
    data: 'idllcperson',
    name: 'Person constituent ID',
  },
  officeLegacyID: {
    data: 'remax_office_id',
    name: 'Office Legacy ID',
  },
  agentsOfficeLegacyID: {
    data: 'officelegacyid',
    name: 'Office Legacy ID',
  },
  officeExternalID: {
    data: 'office_external_id',
    name: 'Office External ID',
  },
  officeInternalID: {
    data: 'datahubofficeid',
    name: 'Internal Office ID',
  },
  sortName: {
    data: 'sortnameforsatellite',
    name: 'Sort Name',
  },
  corporateName: {
    data: 'corporateName',
    name: 'Corporate Name',
  },
  mainOffice: {
    data: 'franchisetype',
    name: 'Main Office',
    transformHTML: (value) => {
      if (value === 'Main' || value === null) {
        return 'Yes';
      }
      return 'No';
    },
  },
  street: {
    data: 'street',
    name: 'Street',
  },
  officeStreet: {
    data: 'totalAddress',
    name: 'Street',
    transformHTML: (value) => {
      if (value) {
        return value.slice(0, value.indexOf(','));
      }
      return '-';
    },
  },
  city: {
    data: 'city',
    name: 'City',
  },
  ZIP: {
    data: 'zip',
    name: 'ZIP',
  },
  country: {
    data: 'country',
    name: 'Country',
    transformHTML: (value) => {
      if (value) {
        return value.split(' ')[1];
      }
      return '-';
    },
  },
  conversion: {
    data: 'Conversion',
    name: 'Conversion',
  },
  franchiseSalesRep: {
    data: 'franchisesalesrep',
    name: 'Franchise Sales Rep',
    transformHTML: (value) => {
      return value !== null && value !== ' ' ? value : '-';
    },
  },
  franchiseSalesRepUniqueID: {
    data: 'franchise_sales_rep_id',
    name: 'Franchise Sales Rep. Unique ID',
    transformHTML: (value) => {
      if (value) {
        return value.length > 5 ? value : '-';
      }
      return '-';
    },
  },
  currentLicenseDate: {
    data: 'datecurrentlicense',
    name: 'Current License Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  licenseDate: {
    data: 'datesigned',
    name: 'License Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  signedDate: {
    data: 'datesigned',
    name: 'Signed Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  firstLicenceDate: {
    data: 'datefirstlicense',
    name: 'first Licence Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  term: {
    data: 'officeTerm',
    name: 'Term',
  },
  renewalDate: {
    data: 'daterenewal',
    name: 'Renewal Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  contractReceived: {
    data: 'datecontractreceived',
    name: 'Contract Received',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  contractOK: {
    data: 'contractOk',
    name: 'Contract OK',
    transformHTML: (value) => {
      return value ? 'Yes' : 'No';
    },
  },
  contractOkDate: {
    data: 'contractokdate',
    name: 'Contract OK Date',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  contractProcess: {
    data: 'datecontractprocessed',
    name: 'Contract Process',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  contractSendToLLC: {
    data: 'datecontractsenttollc',
    name: 'Contract Send To LLC',
    transformHTML: (value) => {
      if (!value) return '-';
      return moment(value).format('DD.MM.YYYY');
    },
  },
  franchiseSize: {
    data: 'franchisesize',
    name: 'Franchise Size',
  },
  currentFranchiseFee: {
    data: 'currentfranchisefee',
    name: 'Current Franchise Fee',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  plannedRenewalFee: {
    data: 'plannedrenewal',
    name: 'Planned Renewal Fee',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  scheduledOpeningDate: {
    data: 'datescheduledopen',
    name: 'Scheduled Opening Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  actualOpeningDate: {
    data: 'dateactualopen',
    name: 'Actual Opening Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  terminationDate: {
    data: 'dateofficetermination',
    name: 'Termination Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  feesPaidInFull: {
    data: 'feesPaidDate',
    name: 'Fees Paid In Full',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  b1FormRecieved: {
    data: 'b1FormReceived',
    name: 'B1 Form Recieved',
    transformHTML: (value) => {
      return value ? 'Yes' : 'No';
    },
  },
  actualFeePaid: {
    data: 'IdealFeeREU',
    name: 'Actual Fee Paid',
    type: 'int',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  actualToREU: {
    data: 'ActualPaymentREU',
    name: 'Actual To REU',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  paymentAccepted: {
    data: 'paymentAccepted',
    name: 'Payment Accepted',
    transformHTML: (value) => {
      return value ? 'Yes' : 'No';
    },
  },
  paymentAcceptedDate: {
    data: 'paymentAcceptedDate',
    name: 'Payment Accepted Date',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  totalAgentCount: {
    data: 'totalagentcount',
    name: 'Total Agent Count',
  },
  totalAgents: {
    data: 'countagents',
    name: 'Total Agents',
    type: 'int',
  },
  newAgents: {
    data: 'agntnewcntForPeriod',
    name: 'New Agents',
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  billableTitleChangesNewAgents: {
    data: 'newAgentCount',
    name: 'New Agents',
    transformReport: (value) => {
      let number = parseFloat(value);
      return number;
    },
  },
  reactivatedAgents: {
    data: 'agntreactivationcntForPeriod',
    name: 'Reactivated Agents',
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
  },
  transferedAgentsIn: {
    data: 'agnttrfincntForPeriod',
    name: 'Transfered Agents In',
    type: 'int',
    transformReport: (value) => {
      return parseInt(value);
    },
  },
  transferedAgentsOut: {
    data: 'agnttrfoutcntForPeriod',
    name: 'Transfered Agents Out',
    type: 'int',
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(value);
    },
  },
  terminationsCount: {
    data: 'agntterminationcntForPeriod',
    name: 'Terminations',
    transformReport: (value) => {
      return parseInt(value);
    },
  },
  TitleBillableChangesterminationsCount: {
    data: 'terminationAgentCount',
    name: 'Terminations',
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(value);
    },
  },
  officeTerminationsCount: {
    data: 'terminationCount',
    name: 'Terminations',
    type: 'int',
    transformReport: (value) => {
      return parseInt(value);
    },
  },
  officeTransferCount: {
    data: 'processedCount',
    name: 'Transfers',
    type: 'int',
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(value);
    },
  },
  officeOpenedCount: {
    data: 'openingCount',
    name: 'New Offices Opened',
    type: 'int',
    transformReport: (value) => {
      if (!value) return 0;
      return parseInt(value);
    },
  },
  totalBO: {
    data: 'countbrokerowner',
    name: 'Total B/O',
  },
  totalPOF: {
    data: 'countpof',
    name: 'total PoF',
  },
  statusOffice: {
    data: 'statusoffice',
    name: 'Status Office',
    transformHTML: (value) => {
      return normalizeOfficeStatusWithColor(value);
    },
    transformReport: (value) => {
      if (!value) return '-';
      return normalizeOfficeStatus(value);
    },
  },
  statusOfficeAggregated: {
    data: 'statusoffice',
    name: 'Current Status',
    transformHTML: (value) => {
      return normalizeOfficeStatusWithColor(value);
    },
    transformReport: (value) => {
      if (!value) return '-';
      return normalizeOfficeStatus(value);
    },
  },
  statusCurrentOffice: {
    data: 'status_current_office',
    name: 'Status Current Office',
  },
  officeRegionName: {
    data: 'regionname',
    name: 'Region',
    link: { route: 'Edit Region', id: 'regionid', paramKey: 'region_id' },
  },

  openingDate: {
    data: 'actual_opening_date',
    name: 'Actual Opening Date',
  },

  annualDuesAnniversary: {
    data: 'annual_dues_anniversary',
    name: 'Annual Dues Anniversary',
    transformHTML: (value) => {
      return value ? moment(value).format('DD.MM.YYYY') : '-';
    },
  },
  office_name_recurring: {
    data: 'office_name',
    name: 'Office Name',
  },
  terminationDateRecurring: {
    data: 'termination_date',
    name: 'Termination Date',
  },
  terminationReasonRecurring: {
    data: 'agent_termination_reason',
    name: 'Termination Reason',
  },
  officeConstituentIDRecurring: {
    data: 'office_constituent_id',
    name: 'Office Constituent ID',
  },
  internalOfficeIDRecurring: {
    data: 'office_internal_id',
    name: 'Internal Office ID',
  },
  officeUniqueIDRecurring: {
    data: 'unique_office_id',
    name: 'Office Unique ID',
  },
  uniqueIDOfficeStatusChanges: {
    data: 'unique_office_id',
    name: 'Unique ID',
  },
  officeUniqueIDMonthlyAgents: {
    data: 'unique_office_id',
    name: 'Office Unique ID',
  },
  statusOfficePersonOtherChanges: {
    data: 'status_current_office',
    name: 'Status Office',
  },
  infoChangesUnique: {
    data: 'unique_office_id',
    name: 'Office Unique ID',
  },
  PrimaryTitleRecurring: {
    data: 'primary_title',
    name: 'Primary Title',
  },
  commencementDateRecurring: {
    data: 'remax_commence_date',
    name: 'Commencement Date',
  },
  commencementDatePersonOtherChanges: {
    data: 'commencement_date',
    name: 'Commencement Date',
  },
  teamStatusRecurring: {
    data: 'team_status',
    name: 'Team Status',
  },
  mobile_phone: {
    data: 'mobile_phone',
    name: 'Mobile phone',
  },
  specializationRecurring: {
    data: 'specialization',
    name: 'Specialization',
  },
  email_address: {
    data: 'email_address',
    name: 'E-Mail',
  },
  languages: {
    data: 'languages',
    name: 'Languages',
  },
  teamleader_name: {
    data: 'teamleader_name',
    name: 'Team Leader Name',
  },
  team_name: {
    data: 'team_name',
    name: 'Team Name',
  },
  team_llc_id: {
    data: 'team_llc_id',
    name: 'Team ID',
  },
  cityRecurring: {
    data: 'city',
    name: 'City',
  },
  personConstituentIDRecurring: {
    data: 'constituent_member_id',
    name: 'Person Constituent ID',
  },
  internalRecurring: {
    data: 'internal_member_id',
    name: 'Internal Member ID',
  },
  dailyNewMembersInternal: {
    data: 'unique_person_id_short',
    name: 'Internal Member ID',
  },
  region_name_recurring: {
    data: 'region_name',
    name: 'Region',
    link: { route: 'Edit Region', id: 'region_id', paramKey: 'region_id' },
  },
  unique_person_id: {
    data: 'person_unique_id',
    name: 'Person Unique ID',
  },
  unique_person_id_recurring: {
    data: 'unique_person_id',
    name: 'Person Unique ID',
  },
  unique_person_id_monthly_agents: {
    data: 'unique_person_id',
    name: 'Unique Person ID',
  },
  statusRecurring: {
    data: 'person_is_active',
    name: 'Status',
  },
  statusMemberRecurring: {
    data: 'status_member',
    name: 'Status Member',
  },
  statusFirstOfficeRecurring: {
    data: 'office_status',
    name: 'Status First Office',
    transformHTML: (value) => {
      return normalizeOfficeStatusWithColor(normalizeOfficeStatus(value));
    },
    transformReport: (value) => {
      return normalizeOfficeStatus(value);
    },
  },
  gciYearRecurring: {
    data: 'gci_year',
    name: 'GCI Year',
    transformReport: (value) => {
      if (!value) {
        let num = Number(0);
        return Number(num.toFixed(2));
      }
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
  },
  addRecurring: {
    data: 'annual_dues_anniversary',
    name: 'Annual Dues Anniversary',
  },
  primary_valid_fromRecurring: {
    data: 'primary_valid_from',
    name: 'Primary Valid From',
  },
  entered_by: {
    data: 'entered_by',
    name: 'Entered By',
  },
  entered_on: {
    data: 'entered_on',
    name: 'Entered On',
  },
  is_satellite_office: {
    data: 'is_satellite_office',
    name: 'Satellite Office',
    transformHTML: (value) => {
      if (value === 1) {
        return 'Yes';
      }
      return 'No';
    },
  },
  actual_opening_date: {
    data: 'actual_opening_date',
    name: 'Actual Open Date First Office',
  },
  phoneRecurring: {
    data: 'direct_phone',
    name: 'Phone',
  },
  tenureRecurring: {
    data: 'tenure_in_years',
    name: 'Tenure',
  },
  officeNumberRecurring: {
    data: 'master_office_id',
    name: 'Office No',
  },
  legacyIDRecurring: {
    data: 'remax_office_id',
    name: 'Legacy ID',
  },
  legacyMemberIDRecurring: {
    data: 'legacy_member_id',
    name: 'Legacy Member ID',
  },
  officeNameRecurring: {
    data: 'office_name',
    name: 'Office Name: RE/MAX...',
  },
  openingDateRecurring: {
    data: 'opening_date',
    name: 'Opening Date',
  },
  changeTypeRecurring: {
    data: 'change_type',
    name: 'Change Type',
  },
  transferDateRecurring: {
    data: 'transfer_date',
    name: 'Transfer Date',
  },
  teamLeaderInternalMemberIDRecurring: {
    data: 'team_llc_id',
    name: 'TL Internal Member ID',
  },
  formerStatusRecurring: {
    data: 'prev_status_member',
    name: 'Former Status Member',
  },
  formerTitleRecurring: {
    data: 'prev_primary_title',
    name: 'Former Title',
  },
  formerTitleValidToRecurring: {
    data: 'prev_primary_title_valid_to',
    name: 'Former Title Valid To',
  },
  formerOfficeUniqueIDRecurring: {
    data: 'prev_office_unique_id',
    name: 'Former Office Unique ID',
  },
  statusCurrentOfficeRecurring: {
    data: 'status_current_office',
    name: 'Status Current Office',
  },
  actualOpeningDateRecurring: {
    data: 'actual_open_date_current_office',
    name: 'Actual Open Date Current Office',
  },
  SatelliteOfficeRecurring: {
    data: 'satellite_office',
    name: 'Satellite Office',
    transformHTML: (value) => {
      if (value === 1) {
        return 'Yes';
      }
      return 'No';
    },
  },
  reactivationDateRecurring: {
    data: 'reactivation_date',
    name: 'Reactivation Date',
  },
  prev_address: {
    data: 'prev_total_address',
    name: 'Previous Address',
  },
  new_address: {
    data: 'total_address',
    name: 'New Address',
  },
  prev_email: {
    data: 'prev_email_address',
    name: 'Previous Email',
  },
  new_email: {
    data: 'email_address',
    name: 'New email_address',
  },
  prev_phone: {
    data: 'prev_phone_number',
    name: 'Previous Phone',
  },
  new_phone: {
    data: 'phone_number',
    name: 'New Phone',
  },
  prev_fax: {
    data: 'prev_fax_number',
    name: 'Previous Fax',
  },
  new_fax: {
    data: 'fax_number',
    name: 'New Fax',
  },
  prev_number: {
    data: 'prev_other_number',
    name: 'Previous Other Number',
  },
  other_number: {
    data: 'other_number',
    name: 'Other Number',
  },
  prev_website: {
    data: 'prev_website',
    name: 'Previous Website',
  },
  new_website: {
    data: 'website',
    name: 'New Website',
  },
  old_office_name: {
    data: 'prev_office_name',
    name: 'Old Office Name',
  },
  new_office_name: {
    data: 'office_name',
    name: 'New Office Name',
  },
  officeIDRecurring: {
    data: 'office_id',
    name: 'Office No',
    transformHTML: (value) => {
      return value ? value.toString() : '-';
    },
  },
  personGroup: {
    data: 'numberOfAgents',
    name: 'Total Billable Count',
  },
  mainOfficeGroup: {
    data: 'office_id',
    name: 'Main Offices',
    nested: [
      {
        data: '99',
        name: 'Actfa-inverse',
        transformHTML: (value) => {
          return 99;
        },
      },
      {
        data: '2',
        name: 'Pending Renewal',
        transformHTML: (value) => {
          return 2;
        },
      },
      {
        data: '0',
        name: 'In Renewal',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '101',
        name: 'Total',
        transformHTML: (value) => {
          return 101;
        },
      },
      {
        data: '0',
        name: 'In Process',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '0',
        name: 'On Hold',
        transformHTML: (value) => {
          return 0;
        },
      },
    ],
  },
  satellitesGroup: {
    data: 'office_id',
    name: 'Satellites',
    nested: [
      {
        data: '4',
        name: 'Actfa-inverse',
        transformHTML: (value) => {
          return 4;
        },
      },
      {
        data: '0',
        name: 'Pending Renewal',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '0',
        name: 'In Renewal',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '0',
        name: 'Total',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '0',
        name: 'In Process',
        transformHTML: (value) => {
          return 0;
        },
      },
      {
        data: '0',
        name: 'On Hold',
        transformHTML: (value) => {
          return 0;
        },
      },
    ],
  },
  allOfficesGroup: {
    data: 'numberOfOffices',
    name: 'Total Office Count',
  },
  yearGroup: {
    data: 'OfficePerformanceCube.transactiondate.month',
    name: `${'Group'}`,
    nested: [
      {
        data: 'gciForPeriod',
        name: 'GCI',
      },
      {
        data: 'volumeForPeriod',
        name: 'Volume',
      },
      {
        data: 'transactionForPeriod',
        name: 'Transactions',
      },
    ],
  },
  agentCountOfficePerformanceCube: {
    data: 'volumeForPeriod',
    name: 'Agent Count',
    nestedColumn: true,
  },
  agentCountMaleOfficePerformanceCube: {
    data: 'volumeForPeriod',
    name: 'Agent Count (Male)',
    nestedColumn: true,
  },
  newAgentCountOfficePerformanceCube: {
    data: 'volumeForPeriod',
    name: 'New Agents',
    nestedColumn: true,
  },
  agentTerminationCountOfficePerformanceCube: {
    data: 'volumeForPeriod',
    name: 'Agents Terminations',
    nestedColumn: true,
  },
  gciPeriodStandardOfficePerformanceCube: {
    data: 'gciForPeriod',
    euro: 'gciForPeriod',
    dollar: 'gciForPeriod',
    local: 'gciForPeriod',
    name: 'GCI',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      let frmatedNumber = Number(number.toFixed(2));
      return frmatedNumber;
    },
    nestedColumn: true,
  },
  volumePeriodStandardOfficePerformanceCube: {
    data: 'volumeForPeriod',
    name: 'Volumes',
    euro: 'volumeForPeriod',
    dollar: 'volumeForPeriod',
    local: 'volumeForPeriod',
    type: 'int',
    currency: true,
    transformHTML: (value, currency = null) => {
      if (value !== '-') return currency + ' ' + formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
    nestedColumn: true,
  },
  transactionsPeriodStandardOfficePerformanceCube: {
    data: 'transactionForPeriod',
    name: 'Transactions',
    currency: true,
    type: 'int',
    transformHTML: (value, currency = null) => {
      if (value !== '-') return formatter.format(value);
      return '-';
    },
    transformReport: (value) => {
      if (!value) return 0;
      let number = parseFloat(value);
      return number;
    },
    nestedColumn: true,
  },
};
