import { FranchiseSalesDashboardService } from '../../../services/modules/franchiseSalesDashboard.service';

export default {
  namespaced: true,

  // state
  state: {
    // card values
    startUpSalesLastYear: 0,
    startUpSalesCurrentYear: 0,

    expansionSalesLastYear: 0,
    expansionSalesCurrentYear: 0,

    conversionSalesLastYear: 0,
    conversionSalesCurrentYear: 0,

    satelliteSalesLastYear: 0,
    satelliteSalesCurrentYear: 0,

    activeFSConsultantsLastYear: 0,
    activeFSConsultantsCurrentYear: 0,

    // FS Consultants performance overview
    fsConsultantsPerformanceOverview: [],

    // checkboxes
    activeChecked: false,
    inactiveChecked: false,
    onHold: false,
    onHoldOverDue: false,

    // regions dropdown value
    selectedRegionNames: null,

    // contract type dropdown
    selectedContractType: null,

    // FS consultant
    selectedFSConsultant: null,

    // options for contract types
    contractOptions: [
      { label: 'Startup Sales', value: '1' },
      { label: 'Expansion Sales', value: '2' },
      { label: 'Conversions', value: '3' },
      { label: 'Satellites', value: '4' },
    ],

    // options for the FS consultants
    consultantsList: [
      { label: 'Anna', value: '1' },
      { label: 'Sascha', value: '2' },
      { label: 'Stephan', value: '3' },
      { label: 'Sushant', value: '4' },
    ],

    totalOfficesCurrentYear: [],

    totalOfficesPreviousYear: [],
  },

  // mutations
  mutations: {
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  // actions
  actions: {
    // get all data needed for the top carsd section
    async getSalesDataForContractType({ state, commit }) {
      // to get the current year value and not last year
      const forCurrentYear = true;

      // make request
      const { result: startUpSalesLastYear, message: startUpLastYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(state.selectedRegionNames, 'Startup Sales');
      const { result: startUpSalesCurrentYear, message: startUpCurrentYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(
          state.selectedRegionNames,
          'Startup Sales',
          forCurrentYear
        );

      const { result: expansionSalesLastYear, message: expansionSalesLastYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(state.selectedRegionNames, 'Expansion Sales');
      const { result: expansionSalesCurrentYear, message: expansionSaleCurrentYearsMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(
          state.selectedRegionNames,
          'Expansion Sales',
          forCurrentYear
        );

      const { result: conversionSalesLastYear, message: conversionSalesLastYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(state.selectedRegionNames, 'Conversions');
      const { result: conversionSalesCurrentYear, message: conversionSalesCurrentYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(
          state.selectedRegionNames,
          'Conversions',
          forCurrentYear
        );

      const { result: satelliteSalesLastYear, message: satelliteSalesLastYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(state.selectedRegionNames, 'Satellites');
      const { result: satelliteSalesCurrentYear, message: satelliteSalesCurrentYearMessage } =
        await FranchiseSalesDashboardService.getSalesDataForContractType(
          state.selectedRegionNames,
          'Satellites',
          forCurrentYear
        );

      // validate data came back
      if (startUpSalesLastYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Startup Sales", false) -> ', startUpLastYearMessage);
      }
      if (startUpSalesCurrentYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Startup Sales", true) -> ', startUpCurrentYearMessage);
      }

      if (expansionSalesLastYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Expansion Sales", false) -> ', expansionSalesLastYearMessage);
      }
      if (expansionSalesCurrentYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Expansion Sales", true) -> ', expansionSaleCurrentYearsMessage);
      }

      if (conversionSalesLastYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Conversions", false) -> ', conversionSalesLastYearMessage);
      }
      if (conversionSalesCurrentYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Conversions", true) -> ', conversionSalesCurrentYearMessage);
      }

      if (satelliteSalesLastYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Satellites", false) -> ', satelliteSalesLastYearMessage);
      }
      if (satelliteSalesCurrentYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Satellites", true) -> ', satelliteSalesCurrentYearMessage);
      }

      // save response
      commit('setState', { startUpSalesLastYear: startUpSalesLastYear.data });
      commit('setState', { startUpSalesCurrentYear: startUpSalesCurrentYear.data });

      commit('setState', { expansionSalesLastYear: expansionSalesLastYear.data });
      commit('setState', { expansionSalesCurrentYear: expansionSalesCurrentYear.data });

      commit('setState', { conversionSalesLastYear: conversionSalesLastYear.data });
      commit('setState', { conversionSalesCurrentYear: conversionSalesCurrentYear.data });

      commit('setState', { satelliteSalesLastYear: satelliteSalesLastYear.data });
      commit('setState', { satelliteSalesCurrentYear: satelliteSalesCurrentYear.data });
    },

    // get all data needed for the ActiveFSConsultants top card
    async getActiveFSConsultants({ state, commit }) {
      // to get the current year value and not last year
      const forCurrentYear = true;
      const { result: activeFSConsultantsLastYear, message: activeFSConsultantsLastYearMessage } =
        await FranchiseSalesDashboardService.getActiveFSConsultants();
      const { result: activeFSConsultantsCurrentYear, message: activeFSConsultantsCurrentYearMessage } =
        await FranchiseSalesDashboardService.getActiveFSConsultants(forCurrentYear);

      // validate data came back
      if (activeFSConsultantsLastYear.data.length == 0) {
        console.warn('getActiveFSConsultants("Last Year") -> ', activeFSConsultantsLastYearMessage);
      }
      if (activeFSConsultantsCurrentYear.data.length == 0) {
        console.warn('getSalesDataForContractType("Current Year") -> ', activeFSConsultantsCurrentYearMessage);
      }

      // save response
      if (activeFSConsultantsLastYear.success && activeFSConsultantsCurrentYear.success)
        commit('setState', { activeFSConsultantsCurrentYear, activeFSConsultantsLastYear });
    },

    // get all consultants for dropdown
    async getFSConsultantsDropdown({ state, commit }) {
      // make request
      const { result, message } = await FranchiseSalesDashboardService.getFSConsultantsDropdown();

      // validate data came back
      if (result.data.length == 0) {
        console.warn('getFSConsultantsDropdown() -> ', message);
        return;
      }

      // make sure the list is formated for the dropdown
      const parsedData = result.data.reduce((_acc, data, index) => {
        const key = Object.keys(data)[0];
        _acc.push({ label: result.data[index][key], value: index });
        return _acc;
      }, []);

      // save response
      commit('setState', { consultantsList: parsedData });
    },

    // get all consultants for dropdown
    async getFSConsultantsPerformanceOverview({ state, commit }) {
      // make request
      const { result, message } = await FranchiseSalesDashboardService.getFSConsultantsPerformanceOverview();

      // validate data came back
      if (result.data.length == 0 || result.data.length == undefined) {
        console.warn('getFSConsultantsPerformanceOverview() -> ', message);
        return;
      }

      // save response
      commit('setState', { fsConsultantsPerformanceOverview: result.data });
    },

    // get all consultants for dropdown
    async getRegionsOverview({ state, commit }) {
      // make request
      const { result, message } = await FranchiseSalesDashboardService.getRegionsOverview();

      // validate data came back
      if (result.data.length == 0 || result.data.length == undefined) {
        console.warn('getRegionsOverview() -> ', message);
        return;
      }

      // save response
      commit('setState', { regionsOverview: result.data });
    },

    async getOfficeDevelopmentdata({ state, commit }) {
      // make request
      const { result: totalOfficesCurrentYear, message: TotalOfficesCurrentYearMessage } =
        await FranchiseSalesDashboardService.getTotalOfficesCurrentYear();
      const { result: totalOfficesPreviousYear, message: totalOfficesPreviousYearMessage } =
        await FranchiseSalesDashboardService.getTotalOfficesPreviousYear();

      // validate data came back
      if (totalOfficesCurrentYear.data.length == 0 || totalOfficesCurrentYear.data.length == undefined) {
        console.warn('getTotalOfficesCurrentYear() -> ', TotalOfficesCurrentYearMessage);
        return;
      }
      if (totalOfficesPreviousYear.data.length == 0 || totalOfficesPreviousYear.data.length == undefined) {
        console.warn('getTotalOfficesPreviousYear() -> ', totalOfficesPreviousYearMessage);
        return;
      }

      // save response
      commit('setState', {
        totalOfficesCurrentYear: totalOfficesCurrentYear.data,
        totalOfficesPreviousYear: totalOfficesPreviousYear.data,
      });
    },
  },
};
