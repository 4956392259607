import { promiseAll } from '@/utils/promiseAll';
import { OfficeEditorService } from '../../../services/modules/officeEditor.service';
import { BaseService } from '../../../services/base.service';
import { init, getOffice } from './init';
import { getDefaultState } from './constants';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  actions: {
    getOffice(store, office_id) {
      return getOffice(store, office_id || store.state.office?.ID);
    },

    init(store, office_id) {
      return init(store, office_id);
    },

    async franchiseAgreement({ state, commit }) {
      try {
        const res = await OfficeEditorService.franchiseAgreement(state.office?.ID);
        const franchiseAgreement = res?.data?.result;
        commit('setState', { franchiseAgreement: { ...state.franchiseAgreement, ...franchiseAgreement } });
      } catch (e) {
        /**/
      }
    },

    async snapshots({ state, commit }) {
      try {
        const res = await OfficeEditorService.snapshots(state.office?.ID);
        const snapshots = res?.data?.result || [];
        commit('setState', { snapshots });
      } catch (e) {
        /**/
      }
    },

    async groups({ state, commit }, params) {
      try {
        const res = await OfficeEditorService.groups(state.office?.remaxEntityID, {
          sort: 'valid_form',
          only_active: '0',
          ...params,
        });
        const groups = res?.data?.result || [];
        commit('setState', { groups });
      } catch (e) {
        /**/
      }
    },

    async officeShareSnapshot({ state, commit }, snapshotID) {
      try {
        if (!snapshotID) {
          commit('setState', { officeShareSnapshot: { ...getDefaultState().officeShareSnapshot, snapshotID: null } });
        } else {
          commit('setState', {
            officeShareSnapshot: { ...getDefaultState().officeShareSnapshot, snapshotID, loading: true },
          });
          const res = await OfficeEditorService.officeShareSnapshot(snapshotID);
          const data = res?.data?.result || null;
          commit('setState', { officeShareSnapshot: { ...getDefaultState().officeShareSnapshot, loading: false, ...data } });
        }
      } catch (e) {
        /**/
      }
      commit('setState', {
        developmentScheduleBySnapshotID: { ...getDefaultState().developmentScheduleBySnapshotID, loading: false },
      });
    },

    async franchiseByHistorySnapshotID({ state, commit }, snapshotID) {
      try {
        if (!snapshotID) {
          commit('setState', {
            franchiseByHistorySnapshotID: {
              ...getDefaultState().franchiseByHistorySnapshotID,
              snapshotID: null,
              data: null,
            },
          });
        } else {
          commit('setState', {
            franchiseByHistorySnapshotID: {
              ...getDefaultState().franchiseByHistorySnapshotID,
              snapshotID,
              loading: true,
              data: null,
            },
          });
		  const res = await BaseService.get(`/v1/office/getFranchiseAgreementInfo`, {
			franchiseAgreementID: snapshotID,
			officeID: state.office?.ID,
		  });
		  const data = res?.data?.data || null;
        //   const res = await OfficeEditorService.franchiseByHistorySnapshotID(snapshotID);
        //   const data = res?.data?.result || null;
          commit('setState', {
            franchiseByHistorySnapshotID: { ...getDefaultState().franchiseByHistorySnapshotID, loading: false, data },
          });
        }
      } catch (e) {
        /**/
      }
      commit('setState', {
        developmentScheduleBySnapshotID: { ...getDefaultState().developmentScheduleBySnapshotID, loading: false },
      });
    },

    async developmentScheduleBySnapshotID({ state, commit }, snapshotID) {
      try {
        if (!snapshotID) {
          commit('setState', {
            developmentScheduleBySnapshotID: { ...state.developmentScheduleBySnapshotID, snapshotID: null, list: [] },
          });
        } else {
          commit('setState', {
            developmentScheduleBySnapshotID: {
              ...state.developmentScheduleBySnapshotID,
              snapshotID,
              loading: true,
              list: [],
            },
          });
          const res = await OfficeEditorService.developmentSchedule(state.office.ID, { snapshotID });
          const list = res?.data?.result || [];
          commit('setState', {
            developmentScheduleBySnapshotID: { ...state.developmentScheduleBySnapshotID, loading: false, list },
          });
        }
      } catch (e) {
        /**/
      }
      commit('setState', { developmentScheduleBySnapshotID: { ...state.developmentScheduleBySnapshotID, loading: false } });
    },

    async save(store, { developmentSnapshot, franchiseAgreement, office }) {
      const officeID = store.state.office?.ID;
      const franchiseAgreementID = store.state.franchiseAgreement?.ID;
      const promises = [];
      if (office) {
        promises.push(
          officeID ? OfficeEditorService.updateOffice(officeID, office) : OfficeEditorService.insertOffice(office)
        );
      }
      if (officeID && developmentSnapshot) {
        promises.push(OfficeEditorService.addOfficeDevelopmentSchedules(officeID, franchiseAgreementID, developmentSnapshot));
        //promises.push(OfficeEditorService.saveOfficeDevelopmentSchedules(franchiseAgreementID, developmentSnapshot));
      }
      if (officeID && franchiseAgreement) {
        promises.push(
			franchiseAgreement.ID
			//   ? OfficeEditorService.put(`officeFranchiseAgreement/${franchiseAgreement.ID}`, franchiseAgreement)
			  ? OfficeEditorService.put(`v1/office/franchiseAgreement/update`, franchiseAgreement)
			  : OfficeEditorService.post(`officeFranchiseAgreements`, franchiseAgreement)
		);
      }
      let [saveOffice, saveDevelopmentSnapshot, saveFranchiseAgreementRes] = await promiseAll(promises);
      return { saveOffice, saveDevelopmentSnapshot, saveFranchiseAgreementRes };
    },

    async persons({ commit, state }, only_active) {
      let list = [];
      only_active = only_active ? '1' : '0';
      try {
        commit('setState', { persons: { only_active, list, loading: true } });
        const res = await OfficeEditorService.persons(state.office?.ID, { only_active });
        list = res?.data?.result || [];
        commit('setState', { persons: { only_active, list, loading: false } });
      } catch (e) {
        commit('setState', { persons: { ...state.persons, loading: false } }); /**/
      }
    },

    async getHistory({ commit, state }, _params: any = {}) {
      const { office_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.history.rowsPerPage || 35;
        let currentPage = params.currentPage || state.history.currentPage || 1;

        const res = await OfficeEditorService.history(office_id || state?.office?.ID, {
          ...params,
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setState', {
          history: {
            list: res?.data?.result || [],
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          },
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNotes({ commit, state }, _params: any = {}) {
      const { office_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.notes.rowsPerPage || 35;
        let currentPage = params.currentPage || state.notes.currentPage || 1;

        const res = await OfficeEditorService.notes(office_id || state?.office?.ID, {
          ...params,
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setState', {
          notes: {
            list: res?.data?.result || [],
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          },
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveNote({ state }, { office_id, entity }) {
      try {
        let res = null;
        if (entity.ID) {
          res = await OfficeEditorService.updateNote(entity, office_id);
        } else {
          res = await OfficeEditorService.insertNote(entity, office_id);
        }
        return res;
      } catch (e) {
        throw e;
      }
    },

    async deleteNote(_, note_id) {
      try {
        const res = await OfficeEditorService.deleteNote(note_id);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeList({ commit }, { regionID, params }) {
      try {
        const res = await OfficeEditorService.getOfficeList(regionID, params);
        const officeList = res?.data?.result;
        commit('setState', { officeList });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getSingleOffice(_, officeID) {
      try {
        const res = await OfficeEditorService.getSingleOffice(officeID);
        return res?.data?.result;
      } catch (e) {
        throw e;
      }
    },
  },
};
