import moment from 'moment';
import { BaseService } from '../base.service';
import convertProxyValue from '../../helpers/convertProxyValue';
import { ResponseWrapper, ErrorWrapper } from '../util';
import subtractOneDayIfLeapYear from '../../helpers/isLeapYear';
const currentYear = new Date().getFullYear();
export class CubeService extends BaseService {
  static get entity() {
    return 'cube';
  }
  static async getCubeToken() {
    try {
      let response = await this.request().get(`cubejs`);
      if(response.data.data == undefined){
        return new ResponseWrapper(response, response.data);
      }
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  //Used in 'Summary' and 'Office Overview'

  //Total Active Offices, On Hold Offices, On Hold Overdue Offices, Renewal Pending, Upcoming Renewals
  static async getOfficeStatuses() {
    try {
      let body = {
        query: {
          dimensions: ['OfficeCube.datescheduledopen', 'OfficeCube.regionid', 'OfficeCube.statusoffice'],
          measures: ['OfficeCube.numberOfOffices'],
          timeDimensions: [
            {
              granularity: 'year',
              dateRange: [`2020-01-01`, `2050-12-31`],
              dimension: 'OfficeCube.dateactualopen',
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  //Table 
  static async getOfficeOverviewTableData1(params) {
    //office_name,	id_office,	city,	GCI YTD, status as of today
    try {
      let body = {
        query: {
          dimensions: [
            "OfficeCube.officeid",
            "OfficeCube.officename",
            "OfficeCube.regionname",
            "OfficeCube.statusoffice",
            "OfficeCube.datahubofficeid",
            "OfficeCube.city",
            "OfficeCube.reportedterminationdate"
        ],
          measures: [
            "PerformanceCube.gciForPeriodEur",
            "PerformanceCube.transactionForPeriod"
          ],
          timeDimensions: [
            {
              granularity: 'year',
              dateRange: [`${Number(params.year)}-01-01`, `${params.monthEndFullDate}`],
              dimension: "PerformanceCube.transactiondate",
            },
          ],
          order: {
            "OfficeCube.datahubofficeid": "asc"
          },
          filters: [
            {
              member: 'OfficeCube.regionid',
              operator: 'equals',
              values: params.regionID
            }
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData1PreviousYear(params) {
    try {
      let body = {
        query: {
          dimensions: [
            "OfficeCube.officeid",
            "OfficeCube.officename",
            "OfficeCube.regionname",
            "OfficeCube.statusoffice",
            "OfficeCube.datahubofficeid",
            "OfficeCube.city"
        ],
          measures: [
            "PerformanceCube.gciForPeriodEur",
            "PerformanceCube.transactionForPeriod"
          ],
          timeDimensions: [
            {
              granularity: 'year',
              dateRange: [`${Number(params.year)-1}-01-01`, `${params.monthEndPreviousYearFullDate}`],
              dimension: "PerformanceCube.transactiondate",
            },
          ],
          order: {
            "OfficeCube.datahubofficeid": "asc"
          },
          filters: [
            {
              member: 'OfficeCube.regionid',
              operator: 'equals',
              values: params.regionID
            }
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData2(params) {
    //agent, new
    try {
      let body = {
        query: 
          {
            timeDimensions: [
              {
                granularity: 'month',
                dateRange: [`${params.year}-${params.month}-01`, `${params.monthEndFullDate}`],
                dimension: "AgentCountCube.agentcountdate",
              }
            ],
            dimensions: [
              "OfficeCube.officeid",
              "OfficeCube.datahubofficeid",
              "OfficeCube.officename",
              "OfficeCube.regionname",
              "OfficeCube.statusoffice",
              "OfficeCube.city",
              "OfficeCube.reportedterminationdate"
            ],
            order: {
              "AgentCountCube.numberOfAgents": "desc"
            },
            filters: [
              {
                member: 'OfficeCube.regionid',
                operator: 'equals',
                values: params.regionID
              },
              {
                or: [
                    {
                        member: "OfficeCube.reportedterminationdate",
                        operator: "afterDate",
                        values: [`${params.monthEndFullDate} 23:59:59`]
                    // values: [new Date(params.year, params.month, 1)]
                    },
                    {
                      "member": "OfficeCube.reportedterminationdate",
                      "operator": "notSet"
                    }
                  ]
              },
              {
                member: "OfficeCube.reportedopeningdate",
                operator: "beforeDate",
                values: [`${params.monthEndFullDate} 23:59:59`]
              },
            ],
            measures: [
              "AgentCountCube.numberOfAgents"
            ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData2AgentCount(params) {
    //agent, new
    try {
      let body = {
        query: 
          {
            dimensions: [
              "OfficeCube.officeid",
              "OfficeCube.datahubofficeid",
              "OfficeCube.officename",
              "OfficeCube.regionname",
              "OfficeCube.statusoffice",
              "OfficeCube.city"
            ],
            filters: [
              {
                member: 'OfficeCube.regionid',
                operator: 'equals',
                values: params.regionID
              },
              {
                  member: "billableTitleChanges.recruitingdate",
                  operator: "afterDate",
                  values: [
                      `${params.year-1}-12-31`
                  ]
              },
              {
                  member: "billableTitleChanges.recruitingdate",
                  operator: "beforeDate",
                  values : [`${params.firstOfNextMonth}`]
              }
            ],
            measures: [
              "billableTitleChanges.newAgentCount",
              "billableTitleChanges.terminationAgentCount",
            ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData4(params) {
    //gci by broker
    try {
      let body = {
        query: {
          dimensions: [
            "TitleLevelPerformance.titlerank",
            "OfficeCube.datahubofficeid",
            "TitleLevelPerformance.regionid"
          ],
          measures: [
            "TitleLevelPerformance.gciForPeriodEur"
          ],
          timeDimensions: [
            {
              dimension: "TitleLevelPerformance.date",
              granularity: "year",
              dateRange: [`${params.year}-01-01`, `${params.monthEndFullDate}`],
            }
          ],
          filters: [
            {
              member: "TitleLevelPerformance.regionid",
              operator: "equals",
              values: params.regionID
            },
          ],  
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData5(params) {
    //average month to report
    try {
      let body = {
        query: {
          dimensions: [
            "AgentMonthsToReportCommissionCube.personid",
            "AgentMonthsToReportCommissionCube.officeid",
            "AgentMonthsToReportCommissionCube.region_id",
            "AgentMonthsToReportCommissionCube.date",
            "AgentMonthsToReportCommissionCube.months",
            "AgentMonthsToReportCommissionCube.datahubofficeid"        
          ],
          order: {
            "OfficeCube.datahubofficeid" : "desc"
          },
          filters: [
            {
              member: "AgentMonthsToReportCommissionCube.region_id",
              operator: "equals",
              values: params.regionID
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficeOverviewTableData6(params) {
    //agent productivity
    try {
      let body = {
        query: 
          {
            "dimensions": [
              "TitleLevelPerformance.agentstatus",
              "TitleLevelPerformance.agentytd",
              "TitleLevelPerformance.officeid",
              "TitleLevelPerformance.tenuregroup",
              "TitleLevelPerformance.personid",
              "TitleLevelPerformance.titlerank",
              "OfficeCube.datahubofficeid"
            ],
            "order": {
              "OfficeCube.datahubofficeid": "asc"
            },
            "filters": [
              {
                "member": "TitleLevelPerformance.regionid",
                "operator": "equals",
                "values": params.regionID
                
              },
              {
                "member": "TitleLevelPerformance.agentstatus",
                "operator": "equals",
                "values": [
                  "1"
                ]
              },
              {
                "member": "TitleLevelPerformance.date",
                "operator": "equals",
                "values": [
                  `${params.year}-${params.month}-01`
                ]
              },
              // {
              //   "member": "TitleLevelPerformance.agentytd",
              //   "operator": "gte",
              //   "values": [
              //     "1"
              //   ]
              // },
              {
                "member": "TitleLevelPerformance.titlerank",
                "operator": "notEquals",
                "values": [
                  "4"
                ]
              },
            ],
            // "timeDimensions": [
            //   {
            //     "dimension": "TitleLevelPerformance.date",
            //     "granularity": "year",
            //     "dateRange": [`${params.year}-01-01`, `${params.monthEndFullDate}`]
            //   }
            // ]
          },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    }catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //Pie Chart calls
  static async getGciContributionsByPrimaryTitlesPie(params){
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid', 'personCube.titleprimary'],
          measures: ['PerformanceCube.gciForPeriodEur'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'year',
              dateRange: [`${params.year}-01-01`, `${subtractOneDayIfLeapYear(params.monthEndFullDate)}`],
            },
          ],
          order: {
            'PerformanceCube.gciForPeriodEur': 'desc',
          },
          filters: [
            {
              member: 'personCube.titleprimary',
              operator: 'equals',
              values: ['Agent', 'Broker/Owner', 'Principal of Franchisee', 'Agent in Training']
            },
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getGciContributionsByPrimaryTitlesPiePreviousYear(params){
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid', 'personCube.titleprimary'],
          measures: ['PerformanceCube.gciForPeriodEur'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'year',
              dateRange: [`${Number(params.year-1)}-01-01`, `${params.previousYear}`],
            },
          ],
          order: {
            'PerformanceCube.gciForPeriodEur': 'desc',
          },
          filters: [
            {
              member: 'personCube.titleprimary',
              operator: 'equals',
              values: ['Agent', 'Broker/Owner', 'Principal of Franchisee', 'Agent in Training']
            },
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getGciContributionsByPrimaryTitlesPieOther(params){
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid', 'personCube.titleprimary'],
          measures: ['PerformanceCube.gciForPeriodEur'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'year',
              dateRange: [`${params.year}-01-01`, `${subtractOneDayIfLeapYear(params.monthEndFullDate)}`],
            },
          ],
          order: {
            'PerformanceCube.gciForPeriodEur': 'desc',
          },
          filters: [
            {
              member: 'personCube.titleprimary',
              operator: 'notEquals',
              values: ['Agent', 'Broker/Owner', 'Principal of Franchisee', 'Agent in Training']
            },
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getGciContributionsByPrimaryTitlesPieOtherPreviousYear(params){
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid', 'personCube.titleprimary'],
          measures: ['PerformanceCube.gciForPeriodEur'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'year',
              dateRange: [`${Number(params.year-1)}-01-01`, `${Number(params.year-1)}-12-31`],
            },
          ],
          order: {
            'PerformanceCube.gciForPeriodEur': 'desc',
          },
          filters: [
            {
              member: 'personCube.titleprimary',
              operator: 'notEquals',
              values: ['Agent', 'Broker/Owner', 'Principal of Franchisee', 'Agent in Training']
            },
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //End pie chart calls

  //Summary side cards
  //termination, processed, office_opening, renewal
  //YTD section
  static async getYTDGCIandTransactions(params) {
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid', 'PerformanceCube.transactiondate'],
          measures: ['PerformanceCube.gciForPeriodEur', 'PerformanceCube.transactionForPeriod'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              dateRange: [`${Number(params.year)-1}-01-01`, `${params.year}-${params.month}-01`],
            },
          ],
          filters: [
            {
            member: 'PerformanceCube.regionid',
            operator: 'equals',
            values: params.regionID,
           }
        ],
        order: [
          [
            'PerformanceCube.transactiondate', 
            'asc'
          ]
        ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  //End pie chart calls

  static async getOfficeTenureAndGCIs(params) {
    //THIS FUNCTION TO REPLACE getYTDGCIOfficeTenure SOON
    try {
      let body = {
        query: {
          timeDimensions: [
            {
              dimension: "TitleLevelPerformance.date",
              granularity: "year",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          order: {
            "TitleLevelPerformance.gciForPeriod": "desc"
          },
          dimensions: [
            "TitleLevelPerformance.tenuregroup",
            "TitleLevelPerformance.officeid",
            "TitleLevelPerformance.personid",
            "TitleLevelPerformance.date",
            "OfficeCube.dateactualopen",
            "OfficeCube.daterenewal",
            "OfficeCube.officeage"
          ],
          filters: [
            {
              member: "TitleLevelPerformance.regionid",
              operator: "equals",
              values: params.regionID
            }
          ],
          measures: [
            "TitleLevelPerformance.gciForPeriod"
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //End pie chart calls
  static async getYTDGCIOfficeTenure(params) {
    //ATTENTION: THIS IS BEING USED BY BOTH OFFICE OVERVIEW AND OFFICE DASHBOARD REPORTS
    // ANY UPDATE SHOULD BE DONE WITH THIS AWARENESS
    let filters = [
      {
        member: "PerformanceCube.regionid",
        operator: "equals",
        values: params.regionID
      }
    ];
    //No need for filtering per region if officeID is available
    if (params.officeID) {
      filters = [
        {
          member: "OfficeCube.officeid",
          operator: "equals",
          values: [`${params.officeID}`]
        }
      ];
    }
    try {
      let month = Number(params.month);
      let year = Number(convertProxyValue(params.year));
      let body = {
        query: {
          measures: [
            "PerformanceCube.gciForPeriodEur"
          ],
          timeDimensions: [
            {
              dimension: "PerformanceCube.transactiondate",
              granularity: "year",
              dateRange: [`${year}-01-01`, `${new Date(year, (month - 1), 0)}`]
            }
          ],
          order: {
            "PerformanceCube.gciForPeriodEur": "desc"
          },
          dimensions: [
            "PerformanceCube.regionid",
            "OfficeCube.dateactualopen",
            "OfficeCube.daterenewal",
            "OfficeCube.officeid",
            "OfficeCube.officeage"
          ],
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  

  static async getTerminatedAgentCounts(params) {
    let filters = [];
    if (params && params.regionID) {
      filters.push(
        {
          member: 'billableTitleChanges.regionid',
          operator: 'equals',
          values: [params.regionID.toString()],
        }
      );
    }
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.terminationAgentCount"
          ],
          order: {
            "billableTitleChanges.terminationAgentCount": "desc"
          },
          dimensions: [
            "billableTitleChanges.idperson",
            "billableTitleChanges.regionid",
            "personCube.ytdcommission",
            "personCube.tenuredays",
            "personCube.remaxcommencedate"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              granularity: "year",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getTerminatedAgentCountsByReason(params = null) {
    let curYear = new Date().getFullYear();
    let date = `${curYear - 1}-12-31`;//End last year
    if (params && params.year) {
      date = `${Number(params.year) - 1}-12-31`;
    }
    let filters = [
      {
        member: "billableTitleChanges.recruitingdate",
        operator: "afterDate",
        values: [`${date}`]
      }
    ];
    if (params && params.regionID) {
      filters.push(
        {
          member: "RegionCube.regionid",
          operator: "equals",
          values: [params.regionID.toString()]
        }
      );
    }
    try {
      let body = {
        query: {
          dimensions: [
            "personCube.terminationreason"
          ],
          order: {
            "billableTitleChanges.terminationAgentCount": "desc"
          },
          measures: [
            "billableTitleChanges.terminationAgentCount"
          ],
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getKPINewTerminatedAgentsCount(params) {
    /**
    * get last week start and end dates
    * We are going one day back  from start and end date by substracting 7 days
    * instead of 6 days so that the query for beforeDate and afterDate work as expected
    * */

    let date = moment().subtract(7 + moment().day(), 'days');
    let endDate = date.add(8 + moment(date).day(), 'days').format('YYYY-MM-DD');

    var month = moment(endDate).format('MM');
    var day = moment(endDate).format('DD');

    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount"
          ],
          dimensions: [
            "OfficeCube.officeid",
            "OfficeCube.datahubofficeid",
            "OfficeCube.officename"
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            },
            {
              member: "billableTitleChanges.recruitingdate",
              operator: "afterDate",
              values: [`${params.year - 1}-12-31`]
            },
            {
              member: "billableTitleChanges.recruitingdate",
              operator: "beforeDate",
              values: [`${params.year}-${month}-${day}`]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  static async getNewTerminatedAgentsCount(params) {
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount"
          ],
          dimensions: [
            "OfficeCube.officeid",
            "OfficeCube.datahubofficeid",
            "OfficeCube.officename"
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            }
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              granularity: "month",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
//unused?
  static async getAllAgentsCount(startDate = "2023-01-01", endDate = "2023-12-31") {
    try {
      let body = {
        query: {
          measures: [
            "AgentCountCube.numberOfAgents"
          ],
          dimensions: [
            "AgentCountCube.regionid",
            "AgentCountCube.officeid",
            "OfficeCube.dateofficetermination"
          ],
          order: {
            "AgentCountCube.numberOfAgents": "desc"
          },
          filters: [
            {
              member: "AgentCountCube.numberOfAgents",
              operator: "gt",
              values: [
                "0"
              ]
            }
          ],
          timeDimensions: [
            {
              dimension: "AgentCountCube.agentcountdate",
              granularity: "year",
              dateRange: [
                startDate,
                endDate
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getMonthlyTerminatedAgents(params) {
    let filters = [
      {
        member: "billableTitleChanges.terminationAgentCount",
        operator: "gt",
        values: ["0"]
      }
    ];
    if (params.regionID) {
      filters.push(
        {
          member: "personCube.regionid",
          operator: "equals",
          values: [params.regionID.toString()]
        }
      );
    }
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.terminationAgentCount"
          ],
          order: {
            "billableTitleChanges.terminationAgentCount": "desc"
          },
          dimensions: [
            "personCube.idperson",
            "personCube.regionid"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              granularity: "month",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getMonthlyAgentCounts(params) {
    let month = new Date().getMonth() + 1;
    if (params.month) {
      month = params.month;
    }
    try {
      let body = {
        query: {
          measures: [
            "AgentRegionCountCube.numberOfAgents"
          ],
          timeDimensions: [
            {
              dimension: "AgentRegionCountCube.agentcountdate",
              granularity: "month",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, month, 0)}`]
            }
          ],
          filters: [
            {
              member: "AgentRegionCountCube.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            }
          ],
          order: {
            "AgentRegionCountCube.agentcountdate": "asc"
          },
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  //unused?
  static async getNewAndTerminatedAgentsCount(params) {
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              dateRange: [`${params.year}-${params.month}-01`, `${new Date(params.year, params.month, 0)}`] //endof month
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getTwelveMonthsNewAndTerminatedAgentsCount(params = null) {
    let date = new Date();
    let date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    //one year back
    let startDate = `${date.getFullYear() - 1}-${date.getMonth() + 1}-${date.getDate()}`;
    //Today's date
    let endDate = `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`;

    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount"
          ],
          filters: [{
            member: "RegionCube.regionid",
            operator: "equals",
            values: [params.regionID.toString()]
          }],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              dateRange: [startDate,endDate]
            }
          ],
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getActiveOfficesCard(params) {
    try {
      let body = {
        query: {
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.reportedopeningdate",
            "OfficeCube.reportedterminationdate",
            "OfficeCube.datahubofficeid"
        ],
        "order": {
            "OfficeCube.dateactualopen": "asc"
        },
        "filters": [
            {
                "member": "RegionCube.regionid",
                "operator": "equals",
                "values": params.regionID
            },
            {
                "member": "OfficeCube.reportedopeningdate",
                "operator": "beforeDate",
                "values": [
                    `${params.firstOfNextMonth}`
                ]
            },
            {
                "or": [
                    {
                        "member": "OfficeCube.reportedterminationdate",
                        "operator": "afterDate",
                        "values": [
                          `${params.monthEndFullDate} 23:59:59`
                        ]
                    },
                    {
                        "and": [
                            {
                                "member": "OfficeCube.reportedterminationdate",
                                "operator": "notSet"
                            }
                        ]
                    }
                ]
            }
        ]
        },
      };
      if(params.datahubOfficeIDs != undefined && params.datahubOfficeIDs.length != 0){
        body.query.filters.push({
          "member": "OfficeCube.datahubofficeid",
          "operator": "equals",
          "values": params.datahubOfficeIDs
        })
      }
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getYTDOfficeCounts(params) {
    try {
      let body = {
        query: {
          dimensions: ['OfficeCountCube.regionid'],
          measures: [
            'OfficeCountCube.numberOfOffices'
          ],
          timeDimensions: [
            {
              dimension: 'OfficeCountCube.officecountdate',
              dateRange: [`${params.year-1}-01-01`, `${params.year}-${params.month}-01`],
              granularity: 'month',
            },
          ],
          filters: [
            {
              member: 'OfficeCountCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getYTDOfficeCountsPreviousYear(params) {
    try {
      let body = {
        query: {
          dimensions: ['OfficeCountCube.regionid'],
          measures: [
            'OfficeCountCube.numberOfOffices'
          ],
          timeDimensions: [
            {
              dimension: 'OfficeCountCube.officecountdate',
              dateRange: [`${params.year-2}-01-01`, `${params.year-1}-${params.month}-01`],
              granularity: 'month',
            },
          ],
          filters: [
            {
              member: 'OfficeCountCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  } 

  static async getYTDOfficeStatuses(params) {
    try {
      let body = {
        query: {
          dimensions: ['OfficeStatusChangesCube.regionid'],
          measures: [
            'OfficeStatusChangesCube.terminationCount',
            'OfficeStatusChangesCube.openingCount',
            'OfficeStatusChangesCube.processedCount',
            'OfficeStatusChangesCube.renewalCount',
          ],
          timeDimensions: [
            {
              dimension: 'OfficeStatusChangesCube.statusdate',
              granularity: 'month',
              dateRange: [`${params.year-1}-01-01`, `${params.monthEnd}`],
            },
          ],
          filters: [
            {
              member: 'OfficeStatusChangesCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  // Summary dashboard
  static async getYTDOfficeStatusesSignedLastYear(params) {
    try {

      let body = {
        query: {
          dimensions: [
            'OfficeStatusChangesCube.regionid',
            'OfficeCube.reportedprocesseddate'
          ],
          measures: [
            'OfficeStatusChangesCube.processedCount',
          ],
          timeDimensions: [
            { //signed in previous year
              dimension: 'OfficeCube.datesigned',
              granularity: 'month',
              dateRange: [`${params.year-1}-01-01`, `${params.year-1}-12-31`],
            },
          ],
          filters: [
            {
              member: 'OfficeStatusChangesCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              //reported this year
              member: 'OfficeCube.reportedprocesseddate',
              operator: "inDateRange",
              values: [`${params.year}-01-01`, `${params.monthEnd}`],
            }
        
          ],
        },
      };

      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }


  static async getYTDAgentCountByRegion(params) {
    try {
      let body = {
        query: {
          measures: [
            "AgentRegionCountCube.numberOfAgents"
          ],
          dimensions: [
            "AgentRegionCountCube.regionid"
          ],
          timeDimensions: [
            {
              dimension: "AgentRegionCountCube.agentcountdate",
              granularity: "month",
              dateRange: [`${params.year-1}-${params.month}-01`, `${params.netMonthFull}`],
            }
          ],
          filters: [
            {
              member: "AgentRegionCountCube.regionid",
              operator: "equals",
              values: params.regionID,
            }
          ]
        }
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getYTDAgentCountByRegionPreviousYear(params) {
    try {
      let body = {
        query: {
          measures: [
            "AgentRegionCountCube.numberOfAgents"
          ],
          dimensions: [
            "AgentRegionCountCube.regionid"
          ],
          timeDimensions: [
            {
              dimension: "AgentRegionCountCube.agentcountdate",
              granularity: "month",
              dateRange: [`${params.year-2}-${params.month}-01`, `${params.monthEndPreviousYearFullDate}`],
            }
          ],
          filters: [
            {
              member: "AgentRegionCountCube.regionid",
              operator: "equals",
              values: params.regionID,
            }
          ]
        }
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getBPlanMonthlyAgentCounts(params = null) {
    let filters = [
      {
        "member": "BusinessplanSectionsRegionCube.property",
        "operator": "contains",
        "values": [
          "agent_count_recruited",
          "agent_count_terminated",
          "agent_count_yoy_growth",
          "agent_count_current_year",
          "agent_count_next_year"
        ]
      }
    ];
    if (params && params.regionID) {
      filters.push(
        {
          member: "BusinessplanSectionsRegionCube.regionid",
          operator: "equals",
          values: [params.regionID.toString()]
        }
      );
    }
    try {
      let body = {
        query: {
          measures: [
            "BusinessplanRegionCube.agntnewcntForPeriod"
          ],
          order: {
            "BusinessplanRegionCube.agntnewcntForPeriod": "desc"
          },
          dimensions: [
            "BusinessplanSectionsRegionCube.jan",
            "BusinessplanSectionsRegionCube.feb",
            "BusinessplanSectionsRegionCube.mar",
            "BusinessplanSectionsRegionCube.apr",
            "BusinessplanSectionsRegionCube.may",
            "BusinessplanSectionsRegionCube.jun",
            "BusinessplanSectionsRegionCube.jul",
            "BusinessplanSectionsRegionCube.aug",
            "BusinessplanSectionsRegionCube.sept",
            "BusinessplanSectionsRegionCube.oct",
            "BusinessplanSectionsRegionCube.nov",
            "BusinessplanSectionsRegionCube.dec",
            "BusinessplanSectionsRegionCube.property",
            "BusinessplanSectionsRegionCube.total"
          ],
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getFullWeekAgentRecruited(params) {
    /**
     * get last week start and end dates
     * We are going one day back  from start and end date by substracting 7 days
     * instead of 6 days so that the query for beforeDate and afterDate work as expected
     * */

    let date = moment().subtract(7 + moment().day(), 'days');

    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = date.add(8 + moment(date).day(), 'days').format('YYYY-MM-DD');

    let filters = [
      {
        "member": "billableTitleChanges.recruitingdate",
        "operator": "afterDate",
        "values": [`${startDate}`]
      },
      {
        "member": "billableTitleChanges.recruitingdate",
        "operator": "beforeDate",
        "values": [`${endDate}`]
      }
    ];
    if (params.regionID) {
      filters.push(
        {
          member: "billableTitleChanges.regionid",
          operator: "equals",
          values: [params.regionID.toString()],
        },
      )
    }
    try {
      let body = {
        query: {
          dimensions: [
            "billableTitleChanges.idoffice",
            "billableTitleChanges.regionid",
            "billableTitleChanges.recruitingdate"
          ],
          "measures": [
            "billableTitleChanges.newAgentCount"
          ],
          filters: filters
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getBPKpis(params) {
    let filters = [
      {
        member: "RegionCube.regionid",
        operator: "equals",
        values: [params.regionID.toString()]
      },
      {
        member: "BusinessplanKPIRegionCube.year",
        operator: "equals",
        values: [`${params.year}`]
      },
      {
        member: "BusinessplanKPIRegionCube.property",
        operator: "contains",
        values: [
          'agents_rec_per_net_growth',
          'agents_terminated',//Terminated agents
          'agents_recruited',//Recruited agents
          'agents_agent_count', //Agents
          'agents_net_growth'
        ]
      }
    ];
    try {
      let body = {
        query: {
          dimensions: [
            "BusinessplanKPIRegionCube.section",
            "BusinessplanKPIRegionCube.year",
            "BusinessplanKPIRegionCube.property",
            "BusinessplanKPIRegionCube.value"
          ],
          order: {
            "BusinessplanKPIRegionCube.regionid": "asc"
          },
          filters: filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getFilteredOfficeMembers(params) {
    let filters = [];
    if (params.officeID) {
      filters.push(
        {
          "member": "personCube.primaryofficeid",
          "operator": "equals",
          "values": [`${params.officeID}`]
        }
      );
    }
    if (params && Array.isArray(params)) {
      filters = params;//reset filters
    }
    try {
      let body = {
        query: {
          "dimensions": [
            "personCube.idperson",
            "personCube.firstname",
            "personCube.lastname",
            "personCube.titleprimary",
            "personCube.officeconstituentid",
            "personCube.primaryofficename"
          ],
          "order": {
            "personCube.firstname": "asc"
          },
          "filters": filters
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getOfficeById(officeID) {
    try {
      let body = {
        query: {
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.datahubofficeid"
          ],
          "limit": 1,
          "filters": [
            {
              "member": "OfficeCube.officeid",
              "operator": "equals",
              "values": [`${officeID}`]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getBillableTitlesAgents(params) {
    //agent, new
    try {
      let body = {
        query: 
          {
            // timeDimensions: [
            //   {
            //     granularity: 'month',
            //     dateRange: [`${params.year-1}-12-31`, `${params.monthEndFullDate}`],
            //     dimension: "AgentCountCube.agentcountdate",
            //   }
            // ],
            dimensions: [
              "OfficeCube.officeid",
              "OfficeCube.datahubofficeid",
              "OfficeCube.officename",
              "OfficeCube.regionname",
              "OfficeCube.statusoffice",
              "OfficeCube.city"
            ],
            order: {
              "AgentCountCube.numberOfAgents": "desc"
            },
            filters: [
              {
                member: 'OfficeCube.regionid',
                operator: 'equals',
                values: params.regionID
              },
              {
                  member: "billableTitleChanges.recruitingdate",
                  operator: "afterDate",
                  values: [
                      `${params.year-1}-12-31`
                  ]
              },
              {
                  "member": "billableTitleChanges.recruitingdate",
                  "operator": "beforeDate",
                  "values" : [`${params.firstOfNextMonth}`]
              }
            ],
            measures: [
              "billableTitleChanges.newAgentCount",
              "billableTitleChanges.terminationAgentCount"
            ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOfficesPerfAgents(params) {
    try {
      let body = {
        query: {
          measures: [
            "OfficePerformanceCube.agntnewcntForPeriod",
            "OfficePerformanceCube.agntterminationcntForPeriod"
          ],
          timeDimensions: [
            {
              dimension: "OfficePerformanceCube.transactiondate",
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          order: {
            "OfficePerformanceCube.agntnewcntForPeriod": "desc"
          },
          dimensions: [
            "OfficePerformanceCube.officeid",
            "OfficeCube.officename",
            "OfficeCube.datahubofficeid"
          ],
          filters: [
            {
              member: "OfficePerformanceCube.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            },
            {
              or: [
                {
                  member: 'OfficeCube.dateofficetermination',
                  operator: 'afterDate',
                  values: [`${params.year}-01-01`],
                },
                {
                  and: [
                    {
                      member: 'OfficeCube.dateofficetermination',
                      operator: 'notSet',
                    },
                  ],
                },
              ]
            },
            {
              "member": "OfficeCube.franchisetype",
              "operator": "notContains",
              "values": ["Satellite"]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getActiveOfficesYTDGCI(params) {
    try {
      let body = {
        query: {
          measures: [
            "PerformanceCube.gciForPeriodEur"
          ],
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.reportedopeningdate",
            "OfficeCube.reportedterminationdate",
            "OfficeCube.daterenewal",
            "OfficeCube.datahubofficeid"
          ],
          "order": {
            "PerformanceCube.transactiondate": "asc"
          },
          timeDimensions: [
            {
              "dimension": "PerformanceCube.transactiondate",
              "dateRange": [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          filters: [
            {
              "member": "OfficeCube.reportedopeningdate",
              "operator": "beforeDate",
              "values": [`${new Date(params.year, params.month, 0)}`]
            },
            {
              member: "OfficeCube.regionid",
              operator: "equals",
              values: params.regionID
            },
            {
              "member": "OfficeCube.franchisetype",
              "operator": "notContains",
              "values": ["Satellite"]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getActiveOffices(params) {
    try {
      let body = {
        query: {
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.officename",
            "OfficeCube.reportedopeningdate",
            "OfficeCube.reportedterminationdate",
            "OfficeCube.daterenewal",
            "OfficeCube.datahubofficeid",
            "OfficeCube.franchisetype",
            "OfficeCube.franchisetype",
            "OfficeCube.regionname",
            "OfficeCube.statusoffice",
            "OfficeCube.city"
          ],
          "order": {
            "PerformanceCube.transactiondate": "asc"
          },
          "filters": [
           {
              or: [
                {
                  member: 'OfficeCube.reportedterminationdate',
                  operator: 'afterDate',
                  values: [`${new Date(params.year, params.month, 1)}`],
                },
                {
                  and: [
                    {
                      member: 'OfficeCube.reportedterminationdate',
                      operator: 'notSet',
                    },
                  ],
                },
              ]
            },
            {
              "member": "RegionCube.regionid",
              "operator": "equals",
              "values": params.regionID
            },
            {
              "member": "OfficeCube.reportedopeningdate",
              "operator": "beforeDate",
              "values": [`${ new Date(params.year, params.month, 1) }`] //first of next month
            },
            {
              "member": "OfficeCube.franchisetype",
              "operator": "notContains",
              "values": ["Satellite"]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getLastYearAgentsCountPerRegion(params) {
    try {
      let body = {
        query: {
          "measures": [
            "AgentRegionCountCube.numberOfAgents"
          ],
          "timeDimensions": [
            {
              "dimension": "AgentRegionCountCube.agentcountdate",
              "granularity": "month",
              "dateRange": "Last year"
            }
          ],
          "filters": [
            {
              "member": "AgentRegionCountCube.regionid",
              "operator": "equals",
              "values": [params.regionID.toString()]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }
  static async getAgentsCountPerOffice(params) {
    try {
      let body = {
        query: {
          "measures": [
            "AgentCountCube.numberOfAgents"
          ],
          "timeDimensions": [
            {
              "dimension": "AgentCountCube.agentcountdate",
              "granularity": "month",
              "dateRange": [`${params.year}-${params.month}-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          "order": {
            "AgentCountCube.numberOfAgents": "desc"
          },
          "dimensions": [
            "AgentCountCube.officeid"
          ],
          "filters": [
            {
              "member": "AgentCountCube.regionid",
              "operator": "equals",
              "values": [params.regionID.toString()]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }
//unused?
  static async getOfficesYTDGCI(params) {
    try {
      let body = {
        query: {
          order: {
            "TitleLevelPerformance.sumCommission": "desc"
          },
          measures: [
            "TitleLevelPerformance.sumCommission"
          ],
          dimensions: [
            "TitleLevelPerformance.officeid",
            "TitleLevelPerformance.regionid",
            "OfficeCube.datahubofficeid",
          ],
          filters: [
            {
              member: "TitleLevelPerformance.regionid",
              operator: "equals",
              values: [params.regionID.toString()]
            },
            {
              member: "OfficeCube.franchisetype",
              operator: "notContains",
              values: ["Satellite"]
            },
            {
              or: [
                {
                  member: 'OfficeCube.dateofficetermination',
                  operator: 'afterDate',
                  values: [`${params.year}-01-01`],
                },
                {
                  and: [
                    {
                      member: 'OfficeCube.dateofficetermination',
                      operator: 'notSet',
                    },
                  ],
                },
              ]
            },
          ],
          timeDimensions: [
            {
              "dimension": "TitleLevelPerformance.date",
              "dateRange": [`${params.year}-01-01`,`${new Date(params.year, params.month, 0)}`]
            }
          ]

        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getOfficesPerfGCI(params) {
    try {
      let body = {
        query: {
          "order": {
            "PerformanceCube.transactiondate": "asc"
          },
          "measures": [
            "PerformanceCube.gciForPeriodEur"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "dateRange": [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.regionid",
              "operator": "equals",
              "values": params.regionID
            },
            {
              "member": "OfficeCube.franchisetype",
              "operator": "notContains",
              "values": ["Satellite"]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  //
  static async getOfficesPerfGCIPreviousYear(params) {
    try {
      let body = {
        query: {
          "order": {
            "PerformanceCube.transactiondate": "asc"
          },
          "measures": [
            "PerformanceCube.gciForPeriodEur"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "dateRange": [`${Number(params.year) - 1}-01-01`, `${new Date(Number(params.year) - 1, params.month, 0)}`]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.regionid",
              "operator": "equals",
              "values": params.regionID
            },
            {
              "member": "OfficeCube.franchisetype",
              "operator": "notContains",
              "values": ["Satellite"]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      return error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  static async getNewTerminatedAgentCount(params) {
    try {
      let body = {
        query: {
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.officename",
            "OfficeCube.datahubofficeid",
            'billableTitleChanges.regionid'
          ],
          measures: [
            'billableTitleChanges.newAgentCount',
            "billableTitleChanges.terminationAgentCount"
          ],
          timeDimensions: [
            {
              dimension: 'billableTitleChanges.recruitingdate',
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`],
            },
          ],
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: params.regionID,
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
//unused?
  static async getTerminationAgentCount(params) {
    try {
      let body = {
        query: {
          "dimensions": [
            "OfficeCube.officeid",
            "OfficeCube.datahubofficeid",
            'billableTitleChanges.regionid'
          ],
          measures: [
            'billableTitleChanges.terminationAgentCount',
          ],
          timeDimensions: [
            {
              dimension: 'billableTitleChanges.recruitingdate',
              dateRange: [`${params.year}-01-01`, `${new Date(params.year, params.month, 0)}`],
            },
          ],
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: [params.regionID.toString()],
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getAgentRecruitedAndTerminated(params) {
    try {
      let body = {
        query: {
          dimensions: ['billableTitleChanges.regionid'],
          measures: [
            'billableTitleChanges.newAgentCount',
            'billableTitleChanges.terminationAgentCount',
          ],
          timeDimensions: [
            {
              dimension: 'billableTitleChanges.recruitingdate',
              granularity: 'month',
              dateRange: [`${params.year-1}-01-01`, `${params.month}`],
            },
          ],
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: "equals",
              values: params.regionID,
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //End YTD section

  //NET section
  static async getNETGci(params) {
    try {
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid'],
          measures: ['PerformanceCube.gciForPeriodEur', 'PerformanceCube.transactionForPeriod'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'month',
              dateRange: [`${Number(params.year)-2}-01-01`, `${params.year}-12-31`]
            },
          ],
          filters: [
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

//unused?
  static async getNETGciMonthly(params) {
    try {
      if (parseInt(params.month) < 10) {
        params.month = '0' + params.month;
        if(params.month.length > 2){
          params.month = params.month.substring(1,3);
        }
      }
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid'],
          measures: ['PerformanceCube.gciForPeriodEur', 'PerformanceCube.transactionForPeriod'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'month',
              dateRange: [`${params.year}-${params.month}-01`, `${params.netMonthFull}`]
            },
          ],
          filters: [
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      if (response.data.data.data && response.data.data.data.length === 0) {
        response.data.data.data = [{
          "PerformanceCube.regionid": params.regionID,
          "PerformanceCube.transactiondate.month": `${params.year}-${params.month}-01`,
          "PerformanceCube.transactiondate": `${params.year}-${params.month}-01`,
          "PerformanceCube.gciForPeriodEur": 0,
          "PerformanceCube.transactionForPeriod": 0
        }]
      }
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
//unused?
  static async getNETGciMonthlyPreviousYear(params) {
    try {
      if (parseInt(params.month) < 10) {
        params.month = '0' + params.month;
        if(params.month.length > 2){
          params.month = params.month.substring(1,3);
        }
      }
      let body = {
        query: {
          dimensions: ['PerformanceCube.regionid'],
          measures: ['PerformanceCube.gciForPeriodEur', 'PerformanceCube.transactionForPeriod'],
          timeDimensions: [
            {
              dimension: 'PerformanceCube.transactiondate',
              granularity: 'month',
              dateRange: [`${Number(params.year)-1}-${params.month}-01`, `${params.netMonthFullLastYear}`]
            },
          ],
          filters: [
            {
              member: 'PerformanceCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      if (response.data.data.data && response.data.data.data.length === 0) {
        response.data.data.data = [{
          "PerformanceCube.regionid": params.regionID,
          "PerformanceCube.transactiondate.month": `${Number(params.year) - 1}-${params.month}-01`,
          "PerformanceCube.transactiondate": `${Number(params.year) - 1}-${params.month}-01`,
          "PerformanceCube.gciForPeriodEur": 0,
          "PerformanceCube.transactionForPeriod": 0
        }]
      }
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //End NET section

  //Summary side card end

  //unused?
  static async getGrossCommisionsAndTransactions(regionID) {
    try {
      let body = {
        query: {
          measures: [
            "PerformanceCube.transactionForPeriod",
            "PerformanceCube.gciForPeriod"  
          ],
          timeDimensions: [
            {
              dimension: "PerformanceCube.transactiondate",
              granularity: "month",
              dateRange: [
                `${currentYear-2}-01-01`,
                `${currentYear}-12-31`              
              ]
            }
          ],
          filters: [
            {
              member: "PerformanceCube.regionid",
              operator: "equals",
              values: [
                regionID.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
    }
  }

  //Recruitment & Terminations
  static async getAgentRecruitedAndTerminatedMonthly(params) {
    try {
      let body = {
        query: {
          dimensions: ['billableTitleChanges.regionid'],
          measures: [
            'billableTitleChanges.newAgentCount',
            'billableTitleChanges.terminationAgentCount',
          ],
          timeDimensions: [
            {
              dimension: 'billableTitleChanges.recruitingdate',
              granularity: 'month',
              dateRange: [`${Number(params.year)-2}-01-01`, `${params.year}-12-31`]
            },
          ],
          filters: [
            {
              member: 'billableTitleChanges.regionid',
              operator: 'equals',
              values: params.regionID,
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //Performance vs. Business Plan
  static async getPerformanceVsBusinessPlan(params) {
    try {
      let body = {
        query: {
          dimensions: [
          "BusinessplanSectionsRegionCube.regionid",
          "BusinessplanSectionsRegionCube.section",
          "BusinessplanSectionsRegionCube.subsection",
          "BusinessplanSectionsRegionCube.property",
          "BusinessplanSectionsRegionCube.total",
          "BusinessplanSectionsRegionCube.jan",
          "BusinessplanSectionsRegionCube.feb",
          "BusinessplanSectionsRegionCube.mar",
          "BusinessplanSectionsRegionCube.apr",
          "BusinessplanSectionsRegionCube.may",
          "BusinessplanSectionsRegionCube.jun",
          "BusinessplanSectionsRegionCube.jul",
          "BusinessplanSectionsRegionCube.aug",
          "BusinessplanSectionsRegionCube.sept",
          "BusinessplanSectionsRegionCube.oct",
          "BusinessplanSectionsRegionCube.nov",
          "BusinessplanSectionsRegionCube.dec"],
          filters: [
            {
              member: 'BusinessplanSectionsRegionCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              member: 'BusinessplanSectionsRegionCube.property',
              operator: 'equals',
              values: [
                "gci_next_year",              //GCI
                "trans_next_year",            //Transactions
                "franchise_sales_next_year",  //Sales processed
                "office_count_openings",      //Opened offices
                "office_count_terminations",  //Terminated offices
                "office_count_next_year",     //Offices
                "agent_count_recruited",      //Recruited agents
                "agent_count_terminated",     //Terminated agents
                "agent_count_next_year",      //Agents
                "agent_count_yoy_growth"      //Agent Net growth
            ],
            }
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  //unused? 
  static async getPersonGCIForOffice() {
    try {
      let body = {
        query: {
          dimensions: ['personCube.idperson', 'personCube.regionid'],
          order: {
            'personCube.idperson': 'asc',
          },
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //Just for testing
  static async getTestQuery() {
    try {
      let body = {
        query: {
          dimensions: ['OfficeCube.dateactualopen'],
          timeDimensions: [
            {
              dimension: 'OfficeCube.dateactualopen',
              dateRange: 'from 2022-01-01 to now',
              granularity: 'year',
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getOnHoldOffices(params) {
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount",
          ],
          dimensions: [
            'OfficeCube.datahubofficeid',
            'OfficeCube.statusoffice',
            'OfficeCube.officename',
            'OfficeCube.regionname',
            'OfficeCube.officeid',
            'OfficeCube.city'
          ],
          filters: [
            {
              member: 'RegionCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              member: 'OfficeCube.datecontractprocessed',
              operator: 'beforeDate',
              values: [params.monthAfterFullDate],
            },
            {
              or: [
                {
                  member: 'OfficeCube.dateactualopen',
                  operator: 'afterDate',
                  values: [params.monthEndFullDate], 
                },
                {
                and: [
                {
                  member: 'OfficeCube.dateactualopen',
                  operator: 'notSet', //51 results using notSet only
                },
                ],
                },
              ]
            },
            {
              or: [
              {
                member: 'OfficeCube.dateofficetermination',
                operator: 'afterDate',
                values: [params.monthEndFullDate],
              },
              {
                and: [
                {
                  member: 'OfficeCube.dateofficetermination',
                  operator: 'notSet', //51 results using notSet only
                },
                ],
              },
            ]
          },
          ],
        },
      };
      if(params.datahubOfficeIDs != undefined && params.datahubOfficeIDs.length != 0){
        body.query.filters.push({
          "member": "OfficeCube.datahubofficeid",
          "operator": "equals",
          "values": params.datahubOfficeIDs
        })
      }
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  static async getOnHoldOverdueOffices(params) {
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount",
            "billableTitleChanges.terminationAgentCount",
          ],
          dimensions: [
            'OfficeCube.datahubofficeid',
            'OfficeCube.statusoffice',
            'OfficeCube.officename',
            'OfficeCube.regionname',
            'OfficeCube.officeid',
            'OfficeCube.city'
          ],
          filters: [
            {
              member: 'RegionCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              member: 'OfficeCube.datecontractprocessed',
              operator: 'beforeDate',
              values: [params.monthAfterFullDate],
            },
            {
              member: 'OfficeCube.datescheduledopen',
              operator: 'beforeDate',
              values: [params.monthEndFullDate],
            },
            {
              or: [
                {
                  member: 'OfficeCube.dateactualopen',
                  operator: 'afterDate',
                  values: [params.monthEndFullDate], 
                },
                {
                and: [
                {
                  member: 'OfficeCube.dateactualopen',
                  operator: 'notSet', //51 results using notSet only
                },
                ],
                },
              ]
            },
            {
              or: [
              {
                member: 'OfficeCube.dateofficetermination',
                operator: 'afterDate',
                values: [params.monthEndFullDate],
              },
              {
                and: [
                {
                  member: 'OfficeCube.dateofficetermination',
                  operator: 'notSet', //51 results using notSet only
                },
                ],
              },
            ]
          },
          ],
        },
      };
      if(params.datahubOfficeIDs != undefined && params.datahubOfficeIDs.length != 0){
        body.query.filters.push({
          "member": "OfficeCube.datahubofficeid",
          "operator": "equals",
          "values": params.datahubOfficeIDs
        })
      }
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getRenewalPendingCount(params){
    try{
      let body = {
        query: {
          dimensions: ['OfficeCube.datahubofficeid'],
          filters: [
            {
              member: 'RegionCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              or: [
                    {
                      member: 'OfficeCube.dateactualopen',
                        operator: 'beforeDate',
                        values: [params.monthEndFullDate],

                    },
                    {
                      and: [{
                        member: 'OfficeCube.dateactualopen',
                        operator: 'set',
                      },
                      ]
                    },
              ]
            },
            {
              or: [
                {
                  member: 'OfficeCube.dateofficetermination',
                  operator: 'afterDate',
                  values: [params.monthEndFullDate],
                },
                 {
                  and:[{
                    member: 'OfficeCube.dateofficetermination',
                    operator: 'notSet',                   
                  }
                  ]
                },
              ],
            },
            {
              member: 'OfficeCube.daterenewal',
              operator: 'beforeDate',
              values: [params.monthEndFullDate],
            },
          ],
        },
      };
      if(params.datahubOfficeIDs != undefined && params.datahubOfficeIDs.length != 0){
        body.query.filters.push({
          "member": "OfficeCube.datahubofficeid",
          "operator": "equals",
          "values": params.datahubOfficeIDs
        })
      }
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    }catch(error){
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getUpcomingRenewals12Months(params){
    try{
      let body = {
        query: {
          dimensions: [ "OfficeCube.datahubofficeid",
                        "OfficeCube.reportedopeningdate",
                        "OfficeCube.reportedterminationdate",
                        "OfficeCube.daterenewal",
                      ],
          filters: [
            {
              member: 'RegionCube.regionid',
              operator: 'equals',
              values: params.regionID,
            },
            {
              member: 'OfficeCube.reportedopeningdate',
              operator: 'beforeDate',
              values: [`${params.firstOfNextMonth}`],
            },
            {
              member: 'OfficeCube.daterenewal',
              operator: 'afterDate',
              values: [`${params.monthEndFullDate}`],
            },
            {
              and:[{
                member: 'OfficeCube.daterenewal',
                operator: 'beforeDate',
                values: [`${params.firstOfNextMonthNextYear}`],
                  }
                ],
            },
            {
              or: [
                {
                  member: 'OfficeCube.reportedterminationdate',
                  operator: 'afterDate',
                  values: [`${params.monthEndFullDate} 23:59:59`],
                },
                {
                  and:[{
                    member: 'OfficeCube.reportedterminationdate',
                    operator: 'notSet',
                  }
                  ]
                },
              ],
            },
          ],
        },
      };
      if(params.datahubOfficeIDs != undefined && params.datahubOfficeIDs.length != 0){
        body.query.filters.push({
          "member": "OfficeCube.datahubofficeid",
          "operator": "equals",
          "values": params.datahubOfficeIDs
        })
      }
      let response = await this.request().post(`${this.entity}`, body);
      return response.data;
    }catch(error){
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  // Recruitment & Retention
  static async getRegionRank() {
    try {
      let body = {
        query: {
          dimensions: ['OfficeCube.officeid', 'OfficeCube.regionalrank'],
          measures: ['OfficeCube.numberOfOffices'],
          timeDimensions: [
            {
              dimension: 'OfficeCube.datefirstlicense',
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getBusinessPlanSectionDataByRegion(regionId, year) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          dimensions: [
            "BusinessplanRegionCube.gcieur",
            "BusinessplanRegionCube.gci",
            "BusinessplanRegionCube.transaction",
            "OfficeCountCube.officecount"
          ],
          timeDimensions: [
            {
              dimension: "BusinessplanRegionCube.transactiondate",
              granularity: 'month',
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            },
          ],
          
          filters: [
            {
              member: "BusinessplanRegionCube.regionid",
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
          order: [
            [
              "BusinessplanRegionCube.transactiondate",
              "asc"
            ]
          ]
        },
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

   static async getBusinessPlanAgentCountByRegion(regionId, year) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          dimensions: [
            "BusinessplanRegionCube.agentmonthcount"
          ],
          timeDimensions: [
            {
              dimension: "BusinessplanRegionCube.transactiondate",
              granularity: "month",
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            }
          ],
          order: [
            [
              "BusinessplanRegionCube.transactiondate",
              "asc"
            ]
          ],
          filters: [
            {
              "member": "BusinessplanRegionCube.regionid",
              "operator": "equals",
              values: [regionId.toString()],
            }
          ]
        }
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }



  static async getBusinessPlanFranchiseSalesDataByRegion(regionId, year) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          measures: ['OfficeStatusChangesCube.processedCount'],
          timeDimensions: [
            {
              dimension: 'OfficeStatusChangesCube.statusdate',
              granularity: 'month',
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            },
          ],
          dimensions: ['OfficeCube.regionid'],
          filters: [
            {
              member: 'OfficeCube.regionid',
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
          order: [['OfficeStatusChangesCube.statusdate', 'asc']],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getBusinessPlanKPIDataByRegion(regionId, startYear, endYear) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          measures: [
            "BusinessplanRegionCube.gciEurForPeriod",
            "BusinessplanRegionCube.transactionForPeriod",
            "BusinessplanRegionCube.offterminationcntForPeriod",
            "BusinessplanRegionCube.agntnewcntForPeriod",
            "BusinessplanRegionCube.agntterminationcntForPeriod"
          ],
          timeDimensions: [
            {
              dimension: "BusinessplanRegionCube.transactiondate",
              granularity: 'year',
              dateRange: [`${startYear}-01-01`, `${endYear}-12-31`],
            },
          ],
          order: [
            [
              "BusinessplanRegionCube.transactiondate",
              "asc"
            ]
          ],
          dimensions: [
            "BusinessplanRegionCube.officeyearcnt",
            "BusinessplanRegionCube.agentyearcnt"
          ],
          filters: [
            {
              member: "BusinessplanRegionCube.regionid",
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
        },
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getBusinessPlanAgentData(regionId, year){
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.terminationAgentCount",
            "billableTitleChanges.newAgentCount"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              granularity: 'month',
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            },
          ],
          order: [
            [
              "billableTitleChanges.recruitingdate",
              "asc"
            ]
          ],
          filters: [
            {
              member: "billableTitleChanges.regionid",
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
        },
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }

  }

  static async getBusinessPlanMonhtlyAgentData(regionId, year){
    try {
      let body = {   
        query: {
          dimensions: [
            "BusinessplanRegionCube.agentmonthcount"
          ],
          timeDimensions: [
            {
              dimension: "BusinessplanRegionCube.transactiondate",
              granularity: 'month',
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            },
          ],
          order: [
            [
              "BusinessplanRegionCube.transactiondate",
              "asc"
            ]
          ],
          filters: [
            {
              member: "BusinessplanRegionCube.regionid",
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
        },
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }

  }

  static async getBusinessPlanOfficeCountData(regionId, year) {
    try {
      let body = {
        query: {
          measures: [
            'OfficeStatusChangesCube.terminationCount',
            'OfficeStatusChangesCube.openingCount'
          ],
          timeDimensions: [
            {
              dimension: 'OfficeStatusChangesCube.statusdate',
              granularity: 'month',
              dateRange: [`${year}-01-01`, `${year}-12-31`],
            },
          ],
          filters: [
            {
              member: 'OfficeStatusChangesCube.regionid',
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
          order: [
            [
              "OfficeStatusChangesCube.statusdate",
              "asc"
            ]
          ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }


    static async getBusinessPlanFranchiseSalesKPIDataByRegion(regionId, startYear, endYear) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          measures: ['OfficeStatusChangesCube.processedCount'],
          timeDimensions: [
            {
              dimension: 'OfficeStatusChangesCube.statusdate',
              granularity: 'year',
              dateRange: [`${startYear}-01-01`, `${endYear}-12-31`],
            },
          ],
          order: [['OfficeStatusChangesCube.statusdate', 'asc']],
          dimensions: ['OfficeStatusChangesCube.regionid'],
          filters: [
            {
              member: 'OfficeStatusChangesCube.regionid',
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getBusinessPlanAgentCountKPIDataByRegion(regionId, startYear, endYear) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          measures: ["AgentCountCube.numberOfAgentsYearAverage"],
          timeDimensions: [
            {
              dimension: "AgentCountCube.agentcountdate",
              granularity: 'year',
              dateRange: [`${startYear}-01-01`, `${endYear}-12-31`],
            },
          ],
          order: [['OfficeStatusChangesCube.statusdate', 'asc']],
          filters: [
            {
              member: 'AgentCountCube.regionid',
              operator: 'equals',
              values: [regionId.toString()],
            },
          ],
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  /* commenting this out before deleting it
  static async getBusinessPlanAgentCountOfficeCountKPIDataByRegion(regionId, startYear, endYear) {
    //this is for the business plan kpi

    try {
      let body = {
        query: {
          dimensions: [
            "BusinessplanYearCountsRegionCube.value",
            "BusinessplanYearCountsRegionCube.percent",
            "BusinessplanYearCountsRegionCube.year",
            "BusinessplanYearCountsRegionCube.property"
          ],
          order: {
            "BusinessplanYearCountsRegionCube.year": "asc"
          },
          filters: [
            {
              "member": "BusinessplanYearCountsRegionCube.regionid",
              "operator": "equals",
              "values": [
                regionId.toString()
              ]
            },
            {
              "member": "BusinessplanYearCountsRegionCube.year",
              "operator": "gt",
              "values": [
                startYear.toString()
              ]
            },
            {
              "member": "BusinessplanYearCountsRegionCube.property",
              "operator": "equals",
              "values": [
                "offices_office_count",
                "agents_agent_count"
              ]
            }
          ]
        },
      };

      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  */

  static async checkOfficeDashboardUserAccess(officeId, year) {
    try {
      let body = {
        query: {

            "measures": [
              "AgentCountCube.numberOfAgents"
            ],
            "timeDimensions": [
              {
                "dimension": "AgentCountCube.agentcountdate",
                "granularity": "month",
                "dateRange": [
                `${year-2}-01-01`,
                `${year}-12-31`
              ]
              }
            ],
            "filters": [
              {
                "member": "AgentCountCube.officeid",
                "operator": "equals",
                "values": [
                  officeId.toString()
                ]
              }
            ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);

      //I don't get the 403 error I get 400 instead of 403 and 400 can be used for other errors too
      if(response.data.success == false && (response.data.status == 400) && response.data.message.includes("Access denied")){
        console.log('trying to access region that is not allowed');
        return false;
      }
      else{
        return true;
      }
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //Office Dashboard Agent Count Trend 
  static async getAgentCountForYearByOffice(officeId, year) {
    try {
      let body = {
        query: {

            "measures": [
              "AgentCountCube.numberOfAgents"
            ],
            "timeDimensions": [
              {
                "dimension": "AgentCountCube.agentcountdate",
                "granularity": "month",
                "dateRange": [
                `${year-2}-01-01`,
                `${year}-12-31`
              ]
              }
            ],
            "filters": [
              {
                "member": "AgentCountCube.officeid",
                "operator": "equals",
                "values": [
                  officeId.toString()
                ]
              }
            ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      console.log(body);
      console.log(response);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  // CM - Previous Year GCI
  static async getOfficeGciByYear(officeID, year) {
    try {
    //   let body = {
    //     query: {
	// 		"measures": [
	// 		  "BusinessplanOfficeCube.gciForPeriod"
	// 		],
	// 		"timeDimensions": [
	// 		  {
	// 			"dimension": "BusinessplanOfficeCube.transactiondate",
	// 			"granularity": "year",
	// 			"dateRange": [
	// 				moment([year]).startOf('year').format('YYYY-MM-DD'),
	// 				moment([year]).endOf('year').format('YYYY-MM-DD')
	// 			]
	// 		  }
	// 		],
	// 		"order": {
	// 		  "BusinessplanOfficeCube.transactiondate": "asc"
	// 		},
	// 		"filters": [
	// 		  {
	// 			"member": "BusinessplanOfficeCube.officeid",
	// 			"operator": "equals",
	// 			"values": [
	// 			  `${officeId}`
	// 			]
	// 		  }
	// 		]
	// 	  }
    //   };
	let body = {
		query: {
			"measures": [
				"OfficePerformanceCube.gciForPeriod",
				// "PerformanceCube.gciForPeriodEur",
				"OfficePerformanceCube.gciForPeriodEur",
			],
			"timeDimensions": [
			  {
				"dimension": "OfficePerformanceCube.transactiondate",
				"granularity": "year",
				"dateRange": [
					moment([year]).startOf('year').format('YYYY-MM-DD'),
					moment([year]).endOf('year').format('YYYY-MM-DD')
				]
			  }
			],
			"order": {
			  "OfficePerformanceCube.gciForPeriod": "desc"
			},
			"dimensions": [
			  "OfficePerformanceCube.officeid"
			],
			"filters": [
			  {
				"member": "OfficePerformanceCube.officeid",
				"operator": "equals",
				"values": [
          `${officeID}`
				]
			  }
			]
		}
	};
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  // CM - Number of Sales Associates
  static async getNumberOfSalesAssociates(officeId, year) {
    try {
      let body = {
        query: {
			"measures": [
			  "AgentCountCube.numberOfAgents"
			],
			// "timeDimensions": [
			//   {
			// 	"dimension": "AgentCountCube.agentcountdate"
			//   }
			// ],
			"timeDimensions": [
				{
				  "dimension": "AgentCountCube.agentcountdate",
				  "granularity": "month",
				  "dateRange": [
					moment(new Date()).subtract(12, 'months').format('YYYY-MM-DD'),
				  	moment(new Date()).format('YYYY-MM-DD'),
				]
				}
			],
			"order": {
			  "AgentCountCube.agentcountdate": "asc"
			},
			"filters": [
			  {
				"member": "AgentCountCube.officeid",
				"operator": "equals",
				"values": [
				  `${officeId}`
				]
			  }
			]
		  }
      };
      let response = await this.request().post(`${this.entity}`, body);

      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard 
  static async getGCITrendByOffice(officeId, year) {
    try {
      let body = {
        query: {
          "measures": [
            "PerformanceCube.gciForPeriodEur"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "granularity": "month",
              "dateRange": [
                `${year-2}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard 
  static async getTransactionTrendByOffice(officeId, year) {
    //this is for the business plan sections
    try {
      let body = {
        query: {
          "measures": [
            "PerformanceCube.transactionForPeriod"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "granularity": "month",
              "dateRange": [
                `${year-2}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
  static async getAgentProductivityByOffice(officeId, year) {
    //this is for the office dashboard
    
    try {
      let body = {
        query: {
          dimensions: [
            "TitleLevelPerformance.agentstatus",
            "TitleLevelPerformance.agentytd",
            "TitleLevelPerformance.officeid",
            "TitleLevelPerformance.tenuregroup",
            "TitleLevelPerformance.personid"
          ],
          "timeDimensions": [
            {
              "dimension": "TitleLevelPerformance.date",
              "granularity": "month",
              "dateRange": [
                `${year-2}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          "filters": [
            {
              "member": "TitleLevelPerformance.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            },
            {
              "member": "TitleLevelPerformance.titlerank",
              "operator": "notEquals",
              "values": [
                "4"
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //pietman to remove
  static async officeYTDSalesData(officeId, reportingPeriod) {
    //this is for the business plan sections
    const reportingPeriodYear = reportingPeriod.toString().substring(0,4);

    try {
      let body = {
        query: {
          "measures": [
            "PerformanceCube.transactionForPeriod"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "granularity": "month",
              "dateRange": [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async officeYTDRentalData(officeId, reportingPeriod) {
    //this is for the business plan sections
    const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
    try {
      let body = {
        query: {
          "measures": [
            "PerformanceCube.transactionForPeriod"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "granularity": "month",
              "dateRange": [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }
  
   static async regionOfficeAgentProd(params){
    //this is for the business plan sections
    //const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
    try {
      let body = {
        query: {
          dimensions: [
            "TitleLevelPerformance.agentstatus",
            "TitleLevelPerformance.agentytd",
            "TitleLevelPerformance.officeid",
            "TitleLevelPerformance.tenuregroup",
            "TitleLevelPerformance.personid",
            "TitleLevelPerformance.titlerank"

          ],
          filters: [
            {
              "member": "TitleLevelPerformance.regionid",
              "operator": "equals",
              "values": [
                params.regionID.toString()
              ]
            },
            {
              "member": "TitleLevelPerformance.date",
              "operator": "equals",
              "values": [
                params.reportingPeriod
              ]
            },
            {
              "member": "TitleLevelPerformance.titlerank",
              "operator": "notEquals",
              "values": [
                "4"
              ]
            },
            {
              "member": "TitleLevelPerformance.agentstatus",
              "operator": "equals",
              "values": [
                "1"
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
   }

   //office dashboard
   static async officeAgentProductivityData(officeId, reportingPeriod) {
    //this is for the business plan sections
    const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
    try {
      let body = {
        query: {
          dimensions: [
            "TitleLevelPerformance.agentstatus",
            "TitleLevelPerformance.agentytd",
            "TitleLevelPerformance.officeid",
            "TitleLevelPerformance.tenuregroup",
            "TitleLevelPerformance.personid",
            "TitleLevelPerformance.titlerank"

          ],
          filters: [
            {
              "member": "TitleLevelPerformance.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            },
            {
              "member": "TitleLevelPerformance.date",
              "operator": "equals",
              "values": [
                reportingPeriod
              ]
            },
            {
              "member": "TitleLevelPerformance.titlerank",
              "operator": "notEquals",
              "values": [
                "4"
              ]
            },
            {
              "member": "TitleLevelPerformance.agentstatus",
              "operator": "equals",
              "values": [
                "1"
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
  //this is for the office performance monyhly table
  static async officeTeamMemberCountData(officeId, year) {
    try {
      let body = {
        query : 
        {
          dimensions: [
            "TeamCube.teammembercount",
          ],
          order: {
            "TeamCube.createdat": "asc"
          },
          timeDimensions: [
            {
              dimension: "TeamCube.createdat",
              granularity: "month",
              dateRange: [
                `${year-1}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          filters: [
            {
              member: "PerformanceCube.officeid",
              operator: "equals",
              values: [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  static async getAgentPerformance(officeId, year) {
    try {
      let body = {
        query: {
          "measures": [
            "PerformanceCube.gciForPeriodEur"
          ],
          "timeDimensions": [
            {
              "dimension": "PerformanceCube.transactiondate",
              "granularity": "month",
              "dateRange": [
                `${year}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          "filters": [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
   static async getAgentGCIPerTenure(officeId, reportingPeriod) {
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: {
          measures: [
            "PerformanceCube.gciForPeriodEur"
          ],
          timeDimensions: [
            {
              dimension: "PerformanceCube.transactiondate",
              dateRange: [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          dimensions: [
            "personCube.tenuredays"
          ],
          filters: [
            {
              "member": "PerformanceCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
  static async getYTDTerminationsByOffice(officeId, reportingPeriod) {
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: {
          measures: [
            "billableTitleChanges.terminationAgentCount"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              dateRange: [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          dimensions: [
            "personCube.tenuredays"
          ],
          filters: [
            {
              "member": "OfficeCube.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
  static async getSalesAndRentalsGCIByOffice(officeId, reportingPeriod) {
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: {
          measures: [
            "TitleLevelPerformance.sumCommissionResidentialSale",
            "TitleLevelPerformance.sumCommissionResidentialRental",
            "TitleLevelPerformance.sumCommissionCommercialSale",
            "TitleLevelPerformance.sumCommissionCommercialRental",
            "TitleLevelPerformance.avgRateEur",
            //"PerformanceCube.gciForPeriodEur"
          ],
          timeDimensions: [
            {
              dimension: "TitleLevelPerformance.date",
              dateRange: [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          filters: [
            {
              "member": "TitleLevelPerformance.officeid",
              "operator": "equals",
              "values": [
                officeId.toString()
              ]
            }
          ]
        },
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard
  static async getSalesAndRentalsTransactionsByOffice(officeId, reportingPeriod) {
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: 
          {
            measures: [
              "TitleLevelPerformance.sumListingResTransSaleAmt",
              "TitleLevelPerformance.sumListingResTransRentalAmt",
              "TitleLevelPerformance.sumResSellingTransRentalAmt",
              "TitleLevelPerformance.sumResSellingTransSaleAmt",
              "TitleLevelPerformance.sumComSellingTransSaleAmt",
              "TitleLevelPerformance.sumComSellingTransRentalAmt",
              "TitleLevelPerformance.sumListingComTransSaleAmt",
              "TitleLevelPerformance.sumListingComTransRentalAmt"
            ],
            timeDimensions: [
              {
                dimension: "TitleLevelPerformance.date",
                dateRange: [
                  `${reportingPeriodYear}-01-01`,
                  `${subtractOneDayIfLeapYear(reportingPeriod)}`
                ]
              }
            ],
            filters: [
              {
                member: "TitleLevelPerformance.officeid",
                operator: "equals",
                values: [
                  officeId.toString()
                ]
              }
            ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  
  //office dashboard
  static async getAgentCountDataByOffice(officeId, reportingPeriod) {
    try {
      let body = {
        query: 
          {
            measures: [
              "AgentCountCube.numberOfAgents"
            ],
            timeDimensions: [
              {
                dimension: "AgentCountCube.agentcountdate",
                dateRange: [
                  `${reportingPeriod}`,
                  `${reportingPeriod}`
                ]
              }
            ],
            filters: [
              {
                member: "AgentCountCube.officeid",
                operator: "equals",
                values: [
                  officeId.toString()
                ]
              }
            ]
          }
      };
      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard performance table 
  static async getNewAndTerminatedAgentDataByOffice(officeId, reportingPeriod) {
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: {
          measures: [
            "billableTitleChanges.terminationAgentCount",
            "billableTitleChanges.newAgentCount"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              dateRange: [
                `${reportingPeriodYear}-01-01`,
                `${subtractOneDayIfLeapYear(reportingPeriod)}`
              ]
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
          filters: [
            {
              member: "OfficeCube.officeid",
              operator: "equals",
              values: [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashbord performance table data
  static async getNewAgentDataByOffice(officeId, year) {
    try {
      let body = {
        query: {
          measures: [
            "billableTitleChanges.newAgentCount"
          ],
          timeDimensions: [
            {
              dimension: "billableTitleChanges.recruitingdate",
              granularity: "month",
              dateRange: [
                `${year-1}-01-01`,
                `${year}-12-31`
              ]
            }
          ],
          order: {
            "billableTitleChanges.recruitingdate": "asc"
          },
          filters: [
            {
              member: "OfficeCube.officeid",
              operator: "equals",
              values: [
                officeId.toString()
              ]
            }
          ]
        }
      };
      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

  //office dashboard gci per broker owner 
  static async getGCIPerBrokerOwnerDataByOffice(officeId, reportingPeriod){
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let body = {
        query: {
          
            measures: [
              "TitleLevelPerformance.gciForPeriodEur"
            ],
            timeDimensions: [
              {
                dimension: "TitleLevelPerformance.date",
                //granularity: "year",
                dateRange: [
                  `${reportingPeriodYear}-01-01`,
                  `${subtractOneDayIfLeapYear(reportingPeriod)}`
                ]
              }
            ],
            order: {
              "TitleLevelPerformance.gciForPeriodEur": "desc"
            },
            dimensions: [
              "TitleLevelPerformance.titlerank",
              "OfficeCube.datahubofficeid",
              "TitleLevelPerformance.regionid"
            ],
            filters: [
              {
                member: "TitleLevelPerformance.titlerank",
                operator: "equals",
                values: [
                  "1"
                ]
              },
              {
                member: "TitleLevelPerformance.officeid",
                operator: "equals",
                values: [
                  officeId.toString()
                ]
              }
            ]
          }
        };
      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }

    //office dashboard gci a
  static async getGCIByOffice(officeId, reportingPeriod){
    try {
      const reportingPeriodYear = reportingPeriod.toString().substring(0,4);
      let newDate = subtractOneDayIfLeapYear(reportingPeriod);
      console.log(newDate)
      let body = {
        query: {
          
            measures: [
            "PerformanceCube.gciForPeriod",
            "PerformanceCube.gciForPeriodEur"
            ],
            timeDimensions: [
              {
                dimension: "PerformanceCube.transactiondate",
                granularity: "year",
                dateRange: [
                  `${reportingPeriodYear}-01-01`,
                  `${subtractOneDayIfLeapYear(reportingPeriod)}`
                ]
              }
            ],
            order: {
              "PerformanceCube.gciForPeriod": "desc"
            },
            filters: [
              {
                member: "PerformanceCube.officeid",
                operator: "equals",
                values: [
                  officeId.toString()
                ]
              }
            ]
          }
        };
      let response = await this.request().post(`${this.entity}`, body);
      
      return response.data.data.data;
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      return message;
    }
  }



  static async getReportData(query) {
    try {
      let body = {
        query: query,
      };
      let response = await this.request().post(`${this.entity}`, body);
      return response.data.data;
    } catch (error) {
		throw error;
    //   const message = error.response.data ? error.response.data.error : error.response.statusText;
    //   return message;
    }
  }

static async getPerformanceVBusinessPlanGCI(params){
  try{
    let body = {
      query: {
        measures: [
          "PerformanceCube.gciForPeriodEur"
        ],
        timeDimensions: [
          {
            dimension: "PerformanceCube.transactiondate",
            dateRange: [
              `${params.year}-01-01`,
              `${params.year}-12-31`
            ]
  }
        ],
        dimensions: [
          "BusinessplanSectionsRegionCube.regionid",
          "BusinessplanSectionsRegionCube.section",
          "BusinessplanSectionsRegionCube.subsection",
          "BusinessplanSectionsRegionCube.property",
          "BusinessplanSectionsRegionCube.total",
          "BusinessplanSectionsRegionCube.jan",
          "BusinessplanSectionsRegionCube.feb",
          "BusinessplanSectionsRegionCube.mar",
        ],
        filters: [
          {
            member: "BusinessplanSectionsRegionCube.regionid",
            operator: "equals",
            values: [
              params.regionID.toString()
            ]
          },
          {
            member: "BusinessplanSectionsRegionCube.property",
            operator: "equals",
            values: [
              params.property //"gci_next_year"
            ],
          },
        ]
      },
    };
    let response = await this.request().post(`${this.entity}`, body);
    return response.data.data.data;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

static async executeReportsQuery(query){
  try {
    let body = {
      query: query
      };
    let response = await this.request().post(`${this.entity}`, body);
    return response.data.data.data;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

static async getReportsQueryRowCount(query){
  try {
    let body = {
      query: query
      };
    let response = await this.request().post(`${this.entity}`, body);
    return response.data.data.data;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

//Needs further filtering 
static async getBDListForRegion(params){
  //Testing Germany which has 4 BD's
  //250607 324106 213421 286048
  let regionsArray = [];
  try{
    regionsArray = Object.values(params.regionID);
    //console.log(regionsArray);
    let body = {
      query: {
        "dimensions": [
          "OfficeCube.businessdeveloperpersonid"
        ],
        "filters": [
          {
            "member": "OfficeCube.regionid",
            "operator": "equals",
            "values": 
            regionsArray
            
          }
        ]
      },
    };
    let response = await this.request().post(`${this.entity}`, body);
    return response.data.data.data;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

static async getBDListWithDatahubOfficeID(params){
  try{
    let paramsArray = [];
    paramsArray = params.map((item) => item["OfficeCube.businessdeveloperpersonid"]);
    //eliminate null values from paramsArray
    paramsArray = paramsArray.filter(function (el) {
      return el != null;
    });
    //convert all items to string
    paramsArray = paramsArray.map((item) => item.toString());
    let body = {
      query: {
        "dimensions": [
          "OfficeCube.businessdeveloperpersonid",
          "OfficeCube.datahubofficeid"
        ],
        "filters": [
          {
            "member": "OfficeCube.businessdeveloperpersonid",
            "operator": "equals",
            "values": paramsArray
          }
        ]
      },
    };
    let response = await this.request().post(`${this.entity}`, body);
    return response.data.data.data;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

static async getBDFirstAndLastName(params){
  try{
  let paramsArray = [];
  paramsArray = params.map((item) => item["OfficeCube.businessdeveloperpersonid"]);
  //eliminate null values from paramsArray
  paramsArray = paramsArray.filter(function (el) {
    return el != null;
  });
  //convert all items to string
  paramsArray = paramsArray.map((item) => item.toString());

  let body = {
      "query": {
          "filters": [
              {
                  "member": "personCube.idperson",
                  "operator": "equals",
                  "values": paramsArray                 
              }
          ],
          "dimensions": [
              "personCube.firstname",
              "personCube.lastname",
              "personCube.idperson"
          ]
      }
  }
  let response = await this.request().post(`${this.entity}`, body);
  return response.data.data.data;
} catch (error) {
  const message = error.response.data ? error.response.data.error : error.response.statusText;
  return message;
}

}

static async getSelectedBDOffices(params){
  try{
    let body = {
      query: {
        "dimensions": [
          "OfficeCube.businessdeveloperpersonid",
          "OfficeCube.datahubofficeid"
        ],
        "filters": [
          {
            "member": "OfficeCube.businessdeveloperpersonid",
            "operator": "equals",
            "values": [
              params.businessdeveloperpersonid
            ]
          }
        ],
        "order": [
          [
            "OfficeCube.businessdeveloperpersonid",
            "desc"
          ],
        ]
      },
    };
    let response = await this.request().post(`${this.entity}`, body);
    let bdOfficeIDs = response.data.data.data.map((data) => data['OfficeCube.datahubofficeid'].toString());
    return bdOfficeIDs;
  } catch (error) {
    const message = error.response.data ? error.response.data.error : error.response.statusText;
    return message;
  }
}

}
