import { BaseService } from '../../../services/base.service';
import { getDefaultState } from './constants';
import { promiseAll } from '@/utils/promiseAll';

async function getList(opts: any = {}): Promise<any> {
  const filters = opts?.filters || [];
  const currentPage = opts.page || 1;
  const rowsPerPage = opts.limit || 20;
  const logs = await BaseService.put(`v1/contractManagement/contractLog/filter`, {
    limit: rowsPerPage,
    offset: rowsPerPage * (currentPage - 1),
    orderBy: null,
    filters: [
      ...filters,
      ...(() => {
        if (opts.status === 'readyToSendInvoice') {
          return [
            {
              fieldName: 'invoice_sent_date',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
            {
              fieldName: 'fee_approval_requested',
              type: 'number',
              logicType: 'AND',
              filter: {
                matchType: 'logical',
                logicalExpression: '!=',
                arguments: [2],
              },
            },
            {
              fieldName: 'fee_approval_requested',
              type: 'number',
              logicType: 'AND',
              filter: {
                matchType: 'logical',
                logicalExpression: '!=',
                arguments: [-1],
              },
            },
          ];
        } else if (opts.status === 'awaitingPayment') {
          return [
            {
              fieldName: 'invoice_sent_date',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NOT NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
            {
              fieldName: 'receiving_date',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
          ];
        } else if (opts.status === 'paymentReceived') {
          return [
            {
              fieldName: 'contract_ok',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
            {
              fieldName: 'receiving_date',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NOT NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
          ];
        } else if (opts.status === 'readyToBeSentToLLC') {
          return [
            {
              fieldName: 'contract_ok',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NOT NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
            {
              fieldName: 'sent_to_remax_llc',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
          ];
        } else if (opts.status === 'readyToBePaidToLLC') {
          return [
            {
              fieldName: 'sent_to_remax_llc',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NOT NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
            {
              fieldName: 'paid_to_remax_llc',
              type: 'date',
              logicType: 'AND',
              filter: {
                matchType: 'NULL',
                logicalExpression: '',
                arguments: [],
              },
            },
          ];
        } else {
          return [];
        }
      })(),
    ],
  });

  try {
    const data = logs?.data?.data?.resultsFound || []; // (logs?.data?.data?.resultsFound || []).sort((a, b) => b.id - a.id);
    const paginationInfo = logs?.data?.data?.paginationInfo;
    const pagination = {
      currentPage,
      rowsPerPage,
      totalPages: paginationInfo?.pageCount || 1,
      totalRows: paginationInfo?.totalResultsFound || 0,
    };
    return {
      data,
      ...pagination,
      filters: opts,
    };
  } catch (e) {
    throw e;
  }
}

async function getListAction({ commit }, opts: any = {}): Promise<any[]> {
  try {
    commit('setState', { loadingList: true });
    const data = await getList(opts);
    commit('setState', {
      loadingList: false,
      list: {
        ...getDefaultState().list,
        loading: false,
        ...data,
      },
    });
    return data;
  } catch (e) {
    commit('setState', { loadingList: false });
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  actions: {
    getList: getListAction,

    _getList(_, opts: any = {}) {
      return getList(opts);
    },

    async init(store, opts) {
      const user = JSON.parse(localStorage.getItem('userInfo') || '{}');

      const [regionsRes, systemAccessesRes] = await promiseAll([
        BaseService.regionsDropdown({ sort: 'entryValue' }),
        BaseService.get(`systemAccessesByRelationID/${user?.activeRelationID}`),
        getListAction(store, opts),
      ]);

      const systemAccesses = systemAccessesRes?.data?.result?.systemAccessPackages || [];

      store.commit('setState', {
        systemAccesses,
        access: systemAccesses.filter((a) => a.packageName === 'Contract Management Read & Update Financial Overview')[0],
        regions: (regionsRes?.data?.result || []).map((r) => ({
          value: r.entryKey,
          label: r.entryValue,
        })),
      });
    },
  },
};
