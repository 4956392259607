import { defaultSystemAccess } from '@/utils/systemAccess';

export const getDefaultState = () => ({
	systemAccess: defaultSystemAccess, 
	
	region: null,
	
	regionalPersons: [],

	regionalFranchiseAgreement: null,

	list: {
		data: [],
		loading: false,
		filters: {},
	},

	rejectedList: {
		data: [],
		loading: false,
		filters: {},
	},

	processedList: {
		data: [],
		loading: false,
		filters: {},
	},

	pendingList: {
		data: [],
		loading: false,
		filters: {},
	},

	paymentCurrency: {
		iso: 'EUR',
		name: 'Euro',
	},

	shareholderTypes: [
		{ label: 'Active Partner', value: '1', },
		{ label: 'Silent Partner', value: '2', },
	],
});
