import axios from 'axios';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import store from '../store';
import $router from '../router';
import { BaseService } from '@/services/base.service';

const API_URL = process.env.VUE_APP_API_URL;
let BEARER = '';
let USER = {};

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async onLoginSuccess(res) {
    try {
      const sessionsRes = await this.get('permission/session', { sort: 'entryValue' });
      store.commit('auth/setSession', sessionsRes?.data);

      if (res?.data?.profilePictureHash) await store.dispatch('auth/getProfilePicture', res?.data?.profilePictureHash);
      this.countries();
      this.phonecodes();
      this.phonecodesWithoutISO();
    } catch (e) {
      //
    }

    _setAuthData({
      accessToken: res?.data?.apiKey,
    });

    let regionRes = await BaseService.getRegionIDByRelationID(res.data.activeRelationID);
    let regionID = regionRes.data.data;
    _setUserData({
      user: { ...res?.data, regionID },
    });

    await store.dispatch('auth/loadAuthenticatedUserData', true);
  }

  static async makeLogin({ userName, password }, relationID = 0, fromSystemAccess = false) {
    try {
      console.log('makingLogin....');
      let res;
      let relation = relationID;
      const response = await axios.get(
        `${API_URL}/login`,
        { params: { userName, password, relationID } },
        { withCredentials: true }
      );
      if (response.status === 200) {
        store.commit('auth/SET_USER_INFORMATION', response.data);
        let activeRelation;
        if (fromSystemAccess) {
          activeRelation = relation;
        } else {
          activeRelation = response.data.activeRelationID;
        }
        const checkAccesses = await axios.get(
          `${API_URL}/login/systemAccessesByPersonID/${response.data.personID}`,
          { params: { personID: response.data.personID } },
          { withCredentials: true }
        );

        //remaxTitle is used in office-overview dashboard
        store.commit('auth/setUserRemaxTitle', checkAccesses.data?.paramQueryResult[0]['remaxTitle']);
        // check if person has multiple regions
        if (checkAccesses?.data?.paramQueryResult?.length > 1 && !fromSystemAccess) {
          //return multiple access to UI code to handle otherwise do normal login
          await store.commit('auth/setMultipleAccesses', true);
          await store.commit('auth/setMultipleAccessList', checkAccesses?.data?.paramQueryResult || []);
          return {
            systemAccessLevels: checkAccesses?.data?.paramQueryResult || [],
            person: response?.data,
          };
        } else {
          res = await axios.get(
            `${API_URL}/login`,
            { params: { userName, password, relationID: activeRelation } },
            { withCredentials: true }
          );
          if (checkAccesses?.data?.paramQueryResult?.length > 1) {
            res.data.multiAccess = true;
            res.data.multiAccessList = checkAccesses?.data?.paramQueryResult;
            res.data.activeRelationID = activeRelation;
          }
          await this.onLoginSuccess(res);
        }
        return response;
      }
    } catch (error) {
      console.log(error);
      throw new error();
      //throw new ErrorWrapper(error);//modal error not needed on login
    }
  }
  //This is important for some users trying to access old urls getting redirected to login page
  static async navigateTo(relativePath) {
    try {
      //if the path does not exists, it generate an error
      await $router.push(relativePath);
    } catch (error) {
      console.log('This path does not exist:', relativePath);
      await $router.push('/');
    }
  }
  //is this needed?
  static async clearSystemAccessCache(relationID, api_key) {
    try {
      const response = await axios.put(`${API_URL}/login/clearSystemAccessCache/${relationID}`, {
        relation_id: relationID,
        api_key: api_key,
      });
      return response;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPermissionSession(selectedRelationID) {
    try {
      const response = await axios.get(`${API_URL}/login/systemAccessesInfoButtonData/${selectedRelationID}`);
      return response;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static makeLogout() {
    // _resetAuthData();
    // $router.push({ path: '/login' });
  }

  static async sendPasswordResetEmail(email, APP_URL) {
    try {
      const response = await axios.get(`${API_URL}/login/checkIfEmailExists/${email}`);
      if (response.data.emailExists) {
        await axios.post(`${API_URL}/login/generateUniqueTAN/${response.data.loginResult.id}`, {
          app_url: APP_URL,
          API_URL: API_URL,
        });
        return response;
      }
    } catch (e) {
      //
    }
  }

  static async getLoginIDByPersonID(ID) {
    try {
      const response = await axios.get(`${API_URL}/getLoginIDByPersonID/${ID}`);
      return response;
    } catch (e) {
      //
    }
  }

  static async loginByToken({ token, password }) {
    try {
      const response = await axios.post(`${API_URL}/login/${token}`, { password: password });
      return response;
    } catch (e) {
      //
    }
  }

  static async refreshTokens() {
    try {
      const response = await axios.post(`${API_URL}/auth/refresh-tokens`, { withCredentials: true });

      _setAuthData({
        accessToken: response.data.data.accessToken,
        exp: _parseTokenData(response.data.data.accessToken).exp,
      });
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      _resetAuthData();
      $router.push({ name: 'login' });
      throw new ErrorWrapper(error);
    }
  }

  static debounceRefreshTokens = this._debounce(() => {
    return this.refreshTokens();
  }, 100);

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const accessTokenExpDate = store.state.auth.accessTokenExpDate - 10;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem('refreshToken'));
  }

  static setRefreshToken(status) {
    if (!['', 'true'].includes(status)) {
      throw new Error(`setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`);
    }

    localStorage.setItem('refreshToken', status);
  }

  static getBearer() {
    return BEARER;
  }

  static getUser() {
    return USER;
  }

  static isLoggedIn() {
    if (localStorage.getItem('bearer') === 'null') return false;
    return true;
  }

  static clearAuthData() {
    this.setBearer(null);
    this.setUserInfo(null);
    $router.push({ name: 'login' });
  }

  static setBearer(accessToken) {
    localStorage.setItem('bearer', accessToken);
    BEARER = `Bearer ${accessToken}`;
  }

  static setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
    USER = user;
  }

  static logout() {
    localStorage.clear();
    //also clear store for dashboards
    store.commit('cube/RESET_STATE');
    store.commit('auth/resetState');
  }

  /**
   * https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
   * @param inner
   * @param ms
   * @returns {function(...[*]): Promise<unknown>}
   * @private	loadAuthenticated
   */
  static _debounce(inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const result = inner();
        resolves.forEach((r) => r(result));
        resolves = [];
      }, ms);

      return new Promise((resolve) => resolves.push(resolve));
    };
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = accessToken.split('.')[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData() {
  // reset tokens
  AuthService.setRefreshToken('');
  AuthService.setBearer('null');
  AuthService.setUserInfo('null');
}

function _setAuthData({ accessToken } = {}) {
  AuthService.setRefreshToken('true');
  AuthService.setBearer(accessToken);
}

function _setUserData({ user } = {}) {
  AuthService.setUserInfo(user);
}
